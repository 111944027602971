module.exports = {
  "nav": {
    "shouye": "Inicio",
    "hanqing": "Mercados",
    "jiaoyi": "Comercio",
    "heyue": "Swap",
    "wode": "Mío"
  },
  "common": {
    "login": "Iniciar Sesión",
    "register": "Registrarse",
    "loginregister": "Iniciar sesión / Registrarse",
    "logout": "Cerrar Sesión",
    "tip": "Consejo",
    "delete": "¿Está seguro de eliminar?",
    "set": "Ajustes",
    "update": "Modificar",
    "more": "Más",
    "loading": "Cargando",
    "zanwu": "No Recuerdo",
    "quxiao": "Cancelar",
    "ok": "Confirmar",
    "noyanzhengma": "Ingrese el CAPTCHA correcto",
    "clearStorage": "Limpiar Cache",
    "telErr": "Ingrese el número de móvil correcto",
    "T404": "La plataforma se está actualizando y el acceso se restablecerá más adelante"
  },
  "loginPage": {
    "mima": "Contraseña",
    "passwordTxt": "Ingrese la contraseña"
  },
  "homePage": {
    "charge": "Depósito",
    "pickup": "Retirar",
    "exchange": "Spot",
    "promotionaward": "Reembolso"
  },
  "pageTitle": {
    "gonggaoxiangqing": "Anuncio",
    "wodezichang": "Saldos",
    "bangzhuxiangqing": "Detalles",
    "fundpwd": "Contraseña de Fondo",
    "yuyanshezhi": "Idioma",
    "huazhuang2": "Transferir",
    "renzheng": "Autenticación",
    "chooseAlterPhoneType": "Número de Teléfono",
    "chooseAlterEmailType": "Correo Electrónico",
    "letterList": "Notificaciones",
    "appDownload": "Descarga la Aplicación",
    "buy": "COMPRAR",
    "sell": "VENDER",
    "cexiao": "Cancelar",
    "time": "Tiempo",
    "type": "Tipo",
    "jiage": "Precio",
    "weituoliang": "Monto",
    "changjiao": "Ejecutado",
    "weituoe": "Pago",
    "over": "Todo cargado",
    "weituojia": "Precio Encomendado",
    "weituoer": "Cantidad Total",
    "quedingchedan": "¿Está seguro de cancelar?",
    "chedaning": "Cancelando",
    "jiaoyidui": "Pares de Monedas",
    "fangxiang": "Dirección",
    "chongzhi": "Reiniciar",
    "xianjia": "Límite",
    "shijia": "Mercado",
    "plan": "Activar",
    "quanbu": "Todos",
    "xianjiaweituo": "Límite",
    "yichexiao": "Cancelado",
    "time1": "La hora de inicio debe ser anterior a la hora de finalización",
    "time2": "La hora de inicio debe ser anterior a la hora de finalización",
    "time3": "La hora de finalización debe ser anterior a la hora actual",
    "cedancg": "Cancelado con éxito",
    "cedansb": "Error al cancelar",
    "mairujiage": "Precio Unitario",
    "yongxu": "SWAP",
    "zhanghu": "Equidad",
    "yugu": "Precio de Liquidación EST",
    "baozheng": "Tasa de Margen",
    "kaicang": "Abrir",
    "baocang": "Liquidación",
    "pincang": "Cerrar",
    "zhanghu2": "Cuenta",
    "pingduo": "Cerrar Largo",
    "kaiduo": "Abierto Largo",
    "pingkong": "Cerrar Corto",
    "kaigong": "Abierto Corto",
    "cufajia": "Precio de Activación",
    "zuiyou": "Mejor Precio",
    "zhang": "Cont",
    "keyong": "Disponible",
    "kekaiduo": "Máx. L",
    "kekaikong": "Máx. C",
    "duocang": "Máx (L)",
    "kongcang": "Máx (C)",
    "kanzhang": "Largo",
    "kandie": "Corto",
    "shuliang": "Monto",
    "chicang": "Posiciones",
    "dangqianweituo": "Ordenes Abiertas",
    "lishiweituo": "Historia",
    "shouyi": "P/G",
    "shouyilv": "Relación P/G",
    "gangganbeishu": "Aprovechar",
    "chicangliang": "Posición",
    "kepingliang": "Aprovechar. Posición",
    "kaichangjun": "Precio de Abrir",
    "zuixinjia": "Último Precio",
    "dangqianbaozheng": "Margen Actual",
    "duotou": "Largo",
    "kongtou": "Corto",
    "baozhengjin": "Margen",
    "weituoshuliang": "Importe Confiado",
    "jiesuanweik": "Liquidación G/P",
    "chengjiaojia": "Precio Final",
    "shouxufei": "Tarifa",
    "xiugaichenggong": "Modificado con éxito"
  },
  "views": {
    "wangjiPass": "¿Contraseña olvidada?",
    "confirmLogOut": "¿Está seguro de cerrar sesión?",
    "personalCenter": "Centro de Usuarios",
    "shezhi": "Establecer con éxito",
    "yuanyouxiang": "Código de Correo Electrónico Antiguo",
    "newyouxiang": "Nuevo Código de Correo Electrónico",
    "fsyouxiang": "Enviar código",
    "xyouxiang": "Nuevo Correo Electrónico",
    "loginPass": "Contraseña",
    "baocun": "Ahorrar",
    "fschenggong": "Enviado correctamente",
    "inputyouxiang": "Ingrese su nueva dirección de correo electrónico",
    "inputOld": "Ingrese el antiguo código de verificación de correo electrónico",
    "inputNew": "Ingrese el nuevo código de verificación de correo electrónico",
    "inputPass": "Ingrese su contraseña de inicio de sesión",
    "OldPass": "Antigua Contraseña de Inicio de Sesión",
    "inputOldPass": "Ingrese su antigua contraseña de inicio de sesión",
    "NewPass": "Nueva Contraseña de Inicio de Sesión",
    "inputNewPass": "Ingrese su nueva contraseña de inicio de sesión",
    "queren": "Confirmar Contraseña",
    "email": "Código de Verificación",
    "liangciPass": "Las contraseñas ingresadas son inconsistentes",
    "shuruCode": "Ingrese el codigo de verificacion",
    "OldZJ": "Antigua Contraseña de Fondo",
    "inputOldZJ": "Ingrese su antigua contraseña de fondo",
    "NewZJ": "Nueva Contraseña de Fondo",
    "inputNewZJ": "Ingrese su nueva contraseña de fondo",
    "augeren": "Informacion Personal",
    "aushang": "Cargar Tarjeta de Identificación",
    "aushenghe": "Revisar",
    "name": "Nombre Completo",
    "inputName": "Ingresa su nombre completo",
    "huzhao": "Número de identificación emitido por el gobierno",
    "inputhuzhao": "Ingrese su número de identificación, pasaporte o licencia de conducir",
    "next": "Próximo",
    "uphuzhao": "Suba la foto de su DNI",
    "shouhuzhao": "Suba una foto suya sosteniendo su identificación",
    "tijiao": "Enviar",
    "tip1": "1. Asegúrese de que su identificación no esté vencida",
    "tip2": "2. El contenido de la firma incluye {hrefArry} manuscrita, firma personal y fecha actual",
    "tip3": "3. Asegúrese de que las fotos y las firmas personales sean claramente visibles",
    "shentijiao": "La información ha sido enviada, por favor espere la revisión.",
    "fanhui": "Devolver",
    "bangding": "Vincular",
    "inputEmail": "Ingrese su dirección de correo electrónico",
    "inputPhone": "Ingrese su número de móvil",
    "kefu": "Póngase en contacto con el servicio de atención al cliente"
  },
  "list": {
    "wufaxiugia": "No puede modificar múltiples cuando tiene posiciones u órdenes no repartidas",
    "submittip1": "Para garantizar la seguridad de sus fondos, primero realice la autenticación del nombre real",
    "submittip2": "Para garantizar la seguridad de sus fondos, establezca primero la contraseña del fondo",
    "shuruweituo": "Por favor ingrese el precio confiado",
    "shuruweituoliang": "Por favor ingrese la cantidad confiada",
    "dayukekai": "La cantidad no puede ser mayor que Máx. L",
    "dayuduocang": "La cantidad no puede ser mayor que Máx (C)",
    "dayukekaikong": "La cantidad no puede ser mayor que Máx. C",
    "dayukongcang": "La cantidad no puede ser mayor que Máx (L)",
    "shuruchufa": "Por favor, ingrese el precio de activación",
    "zhangfubang": "Clasificación",
    "liang": " Volumen",
    "safar": "Haga clic en el botón de abajo en Safari",
    "kuaisuanzhuang": "Elija [Agregar a Inicio]",
    "noAccount": "¿No tiene cuenta aún?",
    "LoginReg": "Registrarse",
    "butigongfuwu": "Por favor, comprenda que su IP o servicio local no está disponible en este momento",
    "renzheng1": "Verificado",
    "renzheng2": "Error al verificar",
    "renzheng3": "Bajo revisión",
    "renzheng4": "Verificar",
    "shenheing": "En revisión, espere pacientemente",
    "inputzaicimima": "Ingrese la contraseña nuevamente",
    "xuantian": "Ingrese el código de invitación (opcional)",
    "yuedu": "He leído y acepto el ",
    "yonghuxieyi": "Acuerdo de Servicio de Usuario",
    "hasAccount": "¿Ya registrado?",
    "denglu": "Iniciar Sesión",
    "gouxuanxieyi": "Lea y verifique el Acuerdo de Servicio de Usuario",
    "inputzijinmima": "Por favor ingrese la contraseña de su fondo",
    "yijianfankui": "Retroalimentación",
    "gao": "Alto",
    "di": "Bajo",
    "shengdu": "Libro de Pedidos",
    "chengjiao": "Completo",
    "zixuan1": "Eliminado de favoritos",
    "zixuan2": "Error al eliminar",
    "zixuan3": "Error al eliminar",
    "zixuan4": "Error al agregar",
    "jiaoyie": "Cantidad Total",
    "chengjiaozonger": "Cantidad Total",
    "chengjiaojun": "Precio Promedio",
    "chengjiaoliang": "Volumen",
    "bigmairu": "Exceder la cantidad máxima disponible ",
    "weituochenggong": "Negociado con éxito",
    "weituoshibai": "Error al intercambiar",
    "yue": "Saldo",
    "bizhong": "Monedas",
    "huazhuanshuliang": "Monto de la Transferencia",
    "inputhzsl": "Por favor ingrese el monto de la transferencia",
    "bunengweifu": "El monto de la transferencia debe ser mayor a 0",
    "dayuhuazhuan": "El monto de la transferencia no puede ser mayor que el monto disponible en su cuenta",
    "huazhuanchenggong": "Transferido con éxito",
    "huazhuangshibai": "Error al transferir"
  },
  "user": {
    "guizeshuoming": "Reglas",
    "shenheweitongguo": "Error al verificar",
    "shiming": "Por favor complete la autenticación de nombre real",
    "fuzhichenggong": "Copiado con éxito",
    "frozen": "Congelado",
    "zhanghuquanyi": "Equidad",
    "baozhenjin": "Aprovechar. Margen",
    "jiaoyishijian": "Tiempo de Transacción"
  },
  "tradePage": {
    "CUSTOM": "Favoritos"
  },
  "chart": {
    "minute": "Minuto"
  },
  "charge": {
    "congbi": "Dirección de Depósito",
    "baocun": "Guardar código QR"
  },
  "heyuePage": {
    "yijianpingcang": "Flash Cerrar",
    "copyerr": "Error al copiar",
    "pincang": "El cont de la posición de cierre no puede ser 0"
  },
  "yaoqin": {
    "zuce": "Fecha de Registro",
    "showPersonInfo": "Ver su perfil",
    "myinvitelink": "Enlace de Invitación",
    "my_column4": "Clase",
    "yaoqing": "Invitación",
    "dengji": "Progreso de Nivel",
    "mubiao": " Objetivo",
    "zong": "Reembolsos Totales",
    "jiaoyi": "Transacción",
    "wode": "Código de Invitación",
    "huodong": "Bono de Actividad"
  },
  "version3": {
    "hash": "Valor Hash",
    "betTip": "Adivina el último bit del valor hash del primer bloque ETH cada 10 minutos, 0-7 son Pequeños, 8-f son Grandes, 0, 2, 4, 6, 8, a, c y e son Pares, 1, 3 , 5, 7, 9, b, d y f son Impares.",
    "betTip2": "Adivina el último bit del valor hash, 0-7 son Pequeños, 8-f son Grandes, 0, 2, 4, 6, 8, a, c y e son Pares, 1, 3, 5, 7, 9, b , d y f son Impares.",
    "betMoneyTip": "Cantidad de por apuesta",
    "doBet": "Apuesta Ahora",
    "issue": "No.",
    "blockHigh": "Altura de Bloque",
    "blockTime": "Tiempo de Bloque",
    "hash2": "Valor Hash",
    "gameRecord": "Registros de Juego",
    "betTime": "Tiempo de Apuestas",
    "pickUpAddress": "Dirección de Retiro",
    "arriveAccountNum": "Cantidad Recibida",
    "remark": "Observación",
    "contachService": "Contactar Servicio al Cliente",
    "totalBetMoney": "Apuestas Totales",
    "unOpen": "Sin Resultados",
    "maintain": "Mantenimiento",
    "hasBingGo": "Ganado",
    "unBingGo": "Perdido",
    "outOfBalance": "El saldo no es suficiente",
    "chooseBetNumTip": "Por favor seleccione la opción de apuesta",
    "chooseBetMoney": "Por favor ingrese la cantidad de por apuesta",
    "minBetAmount": "La cantidad mínima de por apuesta es ",
    "maxBetAmount": "La cantidad máxima de por apuesta es ",
    "chooseWithDrawAddressTip": "Por favor seleccione una dirección de retiro",
    "inputRemarkTip": "Por favor ingrese su dirección de retiro",
    "confirmOrder": "Confirmar",
    "manji": "Nivel VIP Máximo alcanzado",
    "selWithAddress": "Por favor seleccione una dirección de retiro",
    "withNumTip": "El monto del retiro debe ser ",
    "unLiuShui": "Flujo Inacabado",
    "withdrawChannel": "Canal de Retiro"
  },
  "version4": {
    "game": "Juego",
    "baocangIntro": "El capital de su cuenta de Swap de {account} era de 0 USDT en {date}, y, tasa de margen ≤ 0 y se activará la liquidación forzada",
    "geshi": "Por favor ingrese la cantidad correcta",
    "mingc": "Volumen",
    "zhangd": "Cambio",
    "quanqiu": "Global",
    "yiliu": "A-team",
    "yinhang": "Security"
  },
  "version5": {
    "rechargeWay": "Canal de Depósito",
    "inputChargeNum": "Por favor ingrese el monto del depósito",
    "receivePeople": "Tenedor",
    "bankCard": "Tarjeta Bancaria",
    "bank": "Banco",
    "bankNumber": "Cuenta Bancaria",
    "bankProvince": "Provincia Ubicada",
    "bankCity": "Ciudad Ubicada",
    "bankBrancher": "Sucursal Bancaria",
    "rengong": "Depósito y Retiro Manual"
  },
  "version7": {
    "p6": "Por favor enlace su tarjeta de banda primero",
    "jinxingzhong": "En transacción",
    "yiwancheng": "Hecho",
    "shibai": "Fallido",
    "nichen": "Nombre de usuario",
    "p8": "Cantidad de Transacción Única",
    "username": "Ingrese el nombre de usuario"
  },
  "version8": {
    "noArrive0": "La cantidad recibida no puede ser 0",
    "xuantian": "Por favor ingrese el código de invitación",
    "result": "Resultado",
    "color": "Color",
    "hlRule": "Adivina el número entre 0-9. 1, 3, 5, 7, 9 son Verdes, 0, 2, 4, 6, 8 son Rojos, 0 y 5 también son Morados."
  },
  "Transfer": {
    "FriendsReport": "Informe del Equipo",
    "jintian": "Hoy",
    'qiantian' : 'Anteayer',

    "zuotian": "Ayer",
    "benzhou": "Esta Semana",
    "shangzhou": "Semana Pasada",
    "benyue": "Este Mes",
    "shangyue": "Mes Pasado",
    "zuce": "Usuarios de Registro",
    "shoucong": "Usuarios de Primer Depósito",
    "chongzhi": "Usuarios de Depósito",
    "tixian": "Usuarios de Retiro",
    "heyue": "Usuarios de Swap",
    "youxi": "Usuarios del Juego",
    "touzu": "Apuestas de Juegos",
    "zhongjiang": "Juego Ganador",
    "shimingrenshu": "Usuarios de Verificación",
    "status_1": "Revisando",
    "status_3": "Fallido",
    "status_4": "Éxito",
    "jilu": "Registro"
  },
  "version9": {
    "SD1MRule": "Adivina el número de discos de color (rojo y blanco). Los discos del mismo color tienen un número par que es Par y los discos del mismo color tienen un número impar que es Impar.",
    "FourRed": "4-Rojo/0-Blanco",
    "ThreeRedOneWhite": "3-Rojo/1-Blanco",
    "FourWhite": "0-Rojo/4-Blanco",
    "OneRedThreeWhite": "1-Rojo/3-Blanco"
  },
  "version10": {
    "titMode3": "Debe pagar la tarifa de minería para garantizar que la transacción en la cadena se procese al retirar. La tarifa de minería actual es {minTxFee} USDT.",
    "onGame": "En Curso",
    "tuihui": "Devuelto",
    "hasJump": "Éxito",
    "player": "Jugadores",
    "money": "Monto",
    "jump": "Retirar",
    "jumpMulti": "Retiro Múltiple",
    "betMoney": "Monto",
    "autoJumpTip": "Retiro Automático Múltiple (Opcional)",
    "p1": "El múltiplo mínimo debe ser superior a ",
    "p2": "El múltiplo máximo es ",
    "autoJump": "Retiro Automático",
    "boomValue": "Explosión Múltiple",
    "crash": "Explosión",
    "noRepeatOrder": "No apueste repetidamente",
    "jumpSuccess": "Retirado",
    "tip1_plan": "1. El múltiplo de retiro automático es opcional. Después de completar, cobrará automáticamente después de que la aeronave alcance el múltiplo correspondiente, o puede cobrar manualmente antes de que la aeronave se estrelle; si no lo ha rellenado, aún puede cobrar manualmente antes de que el avión se estrelle.",
    "tip2_plan": "2. Tomaremos el {amount}% de las ganancias de los jugadores con retiro.",
    "dizhi": "Por favor ingrese la dirección correcta"
  },
  "version11": {
    "trend": "Tendencias",
    "goldenBroad": "Ganadores",
    "broadTxt": "Felicidades, {userName} ganó <span class='Tcolor'>{winMoney}</span> USDT",
    "TS": "Retirar Dinero",
    "CQK1M": "Bloques de 1 minuto",
    "CQK5M": "Bloques de 5 minutos",
    "CQK": "Bloques de 10 minuto (Cadena Ascendente)",
    "HL": "Rojo_Verde de 1 minuto",
    "HL5M": "Rojo_Verde de 5 minutos",
    "SD1M": "Discos de 1 minuto",
    "SD5M": "Discos de 5 minutos",
    "Gogame": "Ve a Jugar",
    "yinli": "Beneficio",
    "munber": "Beneficio = Beneficio Comercial + Bono de Actividad + Reembolso",
    "Gyinkui": "Beneficio de Juego",
    "Hyinkui": "Beneficio de Swap",
    "Hlijin": "Bono de Actividad",
    "Yfanyong": "Reembolso"
  },
  "version12": {
    "hl10mRule": "Adivina el primer 0-9 contando desde el último bit hasta el frente del valor hash del primer bloque ETH cada 10 minutos, 1, 3, 5, 7, 9 son Verdes, 0, 2, 4, 6, 8 son Rojos , 0 y 5 también son Moradps.",
    "sd10mRule": "Adivina los últimos cuatro bits del valor hash del primer bloque ETH cada 10 minutos, cada código corresponde a un disco, 0-7 son blancos, 8-f son rojos. Los discos del mismo color tienen un número par que es Par y los discos del mismo color tienen un número impar que es Impar.",
    "SD10M": "Discos de 10 minutos (Cadena Ascendente)",
    "HL10M": "Rojo_Verde de 10 minutos (Cadena Ascendente)",
    "refresh": "Actualizar"
  },
  "version13": {
    "kaijiangzhong": "Dibujando",
    "canyu": "Mío",
    "kaijiang": "Acabado",
    "join": "Unirse",
    "buyAmount": "Número de copias a comprar",
    "minBetAmount": "El número mínimo de compra de copias es: ",
    "maxBetAmount": "El número máximo de compra de copias es: ",
    "tipMax": "Por favor tenga en cuenta que no compre más del número máximo de copias",
    "totalVal": "Valor Total",
    "jindu": "Progreso",
    "leave": "Permanecer",
    "goldNumber": "Código Ganador",
    "myGoldNumber": "Mi Código",
    "val": "Valor",
    "mode": "Resto",
    "unJoin": "No Participado",
    "calNumber": "Calculando",
    "eachPrice": "Precio de por copia",
    "currentIssue": "Récords en esta ronda",
    "hisIssue": "50 récords antes del sorteo",
    "showDetail": "Ver",
    "buy": "Comprar",
    "buyTime": "Tiempo de Compra",
    "luckNumber": "Número de Premio",
    "totalBuyAmount": "Cantidad Total de Compra",
    "buyTip2": "Por favor ingrese el formato correcto de por copia",
    "goldMember": "Miembro del Premio",
    "openTime": "Tiempo de Dibujar",
    "Wtime": "Tiempo estimado de mantenimiento",
    "invitationNote": "Instrucciones de Invitación",
    "p1": "<ins class='paramTitle'>Invitar amigos</ins>Ingrese al centro de invitaciones, copie el enlace de invitación o el código de invitación y compártalo con sus amigos. Los amigos pueden convertirse en miembros subordinados si se registran con su código de invitación.",
    "p2": "<ins class='paramTitle'>Obtenga reembolsos</ins>Puede obtener los reembolsos correspondientes cuando intercambie invitados, que es hasta tres niveles de invitados. Por ejemplo, si invita a su amigo A, A invita a B y B invita a C, entonces A, B y C pueden intercambiar juegos, intercambios y otras transacciones en la plataforma. También puede obtener los reembolsos correspondientes en estas acciones.",
    "fuk": "Por favor pague",
    "guoq": "Caducado",
    "exchtishi": "Por favor utilice su tarjeta bancaria certificada para enviar fondos a la cuenta anterior.",
    "exchtishi2": "Por favor no tenga en cuenta BTC, USDT y otra información en el proceso de envío para evitar la interceptación de remesas o cualquier otro problema.",
    "quxiaoOrder": "Cancelar el Pedido",
    "chengong": "He pagado correctamente",
    "fukuanren": "Nombre del Pagador",
    "fukuanrenTi": "Por favor ingrese el nombre del pagador",
    "zhanghao": "Cuenta de Pago",
    "zhanghaoTi": "Por favor ingrese su cuenta de pago",
    "yihang": "Pagando Banco",
    "yihangTi": "Por favor ingrese su banco pagador",
    "jietu": "Captura de Pantalla de Pago",
    "jietuTi": "Por favor suba su captura de pantalla de pago",
    "queren": "Por favor confirme que ha pagado",
    "queren2": "En caso de confirmación maliciosa, la cuenta será congelada",
    "YYYG": "Suerte 1U",
    "dragon": "Clasificación",
    "paimng": "No."
  },
  "version15": {
    "only60Days": "Solo conserva los datos en un plazo de 60 días",
    "only100Records": "Mostrar solo los últimos 100 registros",
    "status": "Estado",
    "luckyWheel": "Sorteo",
    "option1": "Bono",
    "option2": "Thanks",
    "option3": "Once More",
    "freeTimes": "Oportunidades de Dibujo",
    "p1": "Obtendrá {registerGiveCount} oportunidades para dibujar después de un registro exitoso.",
    "p2": "Si el monto del depósito llega a {todayRechargeCondition} USDT ayer (sujeto a la zona horaria UTC+0), entonces puede obtener {rechargeGiveCount} oportunidades de sorteo.",
    "p3": "Ayer (en la zona horaria UTC+0) la cantidad de apuestas válidas (excluyendo spot y contrato) alcanzó {todayBettingCondition} USDT, entonces puede obtener {bettingGiveCount} oportunidades de sorteo.",
    "continuDraw": "Continuar Dibujando",
    "noFreeTimeTip": "Ha agotado todas sus oportunidades de dibujo."
  },
  "version16": {
    "totalPerMoney": "Pedidos Totales",
    "notFu": "El monto de la apuesta no puede ser 0",
    "find": "Descubrir",
    "channel": "Canal"
  },
  "version17": {
    "drawWarmTip": "Los tiempos de los sorteos pueden tardar entre diez minutos y media hora en confirmarse. Por favor espere pacientemente.",
    "level": "Nivel",
    "upLimit": "Limitación de Actualización",
    "chatRoom": "Charlar",
    "group": "Chats Grupales",
    "privateChat": "Chats privados",
    "groupManager": "Administrador de Grupo",
    "groupMember": "Miembro de Grupo",
    "groupSetting": "Configuración de Grupo",
    "totalChargeMoney": "Importe del Depósito Acumulativo",
    "vip9Tip": "Nivel VIP máximo alcanzado",
    "dataTime": "Tiempo de Datos",
    "sortType": "Orden de Clasificación",
    "loginTime": "Hora de Inicio de Sesión",
    "lowerPeople": "Invitados",
    "chargeNum": "Cantidad del Depósito",
    "withdrawNum": "Cantidad de Retiro",
    "lowerReport": "Informe del equipo",
    "lowerUserBetNum": "Apuestas de Invitados",
    "lowerUserAwardNum": "Ganador de Invitados",
    "lowerUserChargeNum": "Depósito de Invitados",
    "lowerUserWithdrawNum": "Retiro de Invitados",
    "firstRechargeNum": "Importe del primer depósito",
    "warmTip": "Mostrar los datos de hoy automáticamente",
    "publishNotice": "Publicar",
    "editNotice": "Editar Aviso",
    "noticeTime": "Tiempo de Aviso",
    "inputNoticeTip": "Por favor ingrese el contenido del aviso",
    "tip": "Después de habilitar 'Todo prohibido', solo los administradores de grupo pueden hablar",
    "allNotSpeak": "Todo Prohibido",
    "canSpeakLevel": "Nivel de Habla",
    "eryuanqiquan": "Opción Binaria",
    "optionHelpText": "Predecir los altibajos del próximo período de tiempo. El precio al comienzo de cada período es el precio inicial y el precio al final de cada período es el precio de liquidación. Si el precio de liquidación es mayor que el precio de salida, es alcista (largo), y si el precio de liquidación es menor que el precio de salida, es bajista (corto).",
    "optionRecord": "Registros de Opciones",
    "calcuteDirection": "Dirección de Predicción",
    "startPrice": "Precio Inicial",
    "endPrice": "Precio Acordado",
    "equal": "Igual",
    "waitResult": "Liquidación Pendiente",
    "up": "Largo",
    "down": "Corto",
    "findPassword": "Recuperar Contraseña",
    "calTime": "Tiempo de Predicción",
    "calWeek": "Período de Predicción",
    "kanzhang": "Largo",
    "kandie": "Corto"
  },
  "version18": {
    "issue": "No.",
    "vipSort": "VIP",
    "jinyan": "Silenciado",
    "weijinyan": "Sin Silenciado",
    "confirmDelete": "¿Está seguro de que desea eliminar el miembro?",
    "confirmUnSpeak": "¿Está seguro de que desea silenciar al miembro?",
    "confirmSpeak": "¿Está seguro de que desea activar el sonido del miembro?",
    "confirmAdd": "¿Está seguro de que desea agregar el miembro?",
    "addMember": "Agregar nuevo miembro",
    "delMember": "Eliminar el miembro",
    "lockSpeak": "Silenciado",
    "unLockSpeak": "No Silenciado",
    "lockSpeakSelMember": "Silenciado el Miembro",
    "unLockSpeakSelMember": "Sin Silenciar al Miembro",
    "noSelMemberTip": "Por favor seleccione el miembro",
    "recentlyPlayGame": "Jugado con frecuencia",
    "sendMsg": "Enviar Mensaje",
    "addGroupMember": "Agregar Nuevo Miembro",
    "picTip": "[Imagen]",
    "time3": "Hace 1 semana",
    "chooseAll": "Seleccione Invertir",
    "personalPage": "Página Personal",
    "CQK3M": "Blocos de 3 minutos",
    "HL3M": "Rojo_Verde de 3 minutos",
    "SD3M": "Discos de 3 minutos"
  },
  "version19": {
    "avatar": "Avatar",
    "share": "Compartir",
    "todayProfit": "Beneficio de Hoy",
    "followBet": "Hacer un Seguimiento",
    "Jump": "IR",
    "over": "Acabado",
    "optionNumber": "Usuarios de Opciones",
    "optionBet": "Apuestas de Opciones",
    "optionReward": "Ganadora de Opción ",
    "optionProfit": "Beneficio de Opción",
    "RG_2_0": "Rojo",
    "RG_5_1": "Morado",
    "RG_2_1": "Verde",
    "DS_16_0": "1111",
    "DS_16_1": "0000",
    "DS_4_0": "1110",
    "DS_4_1": "1000",
    "DS_2_0": "Par",
    "DS_2_1": "Impar",
    "BLK_2_0": "Grande",
    "BLK_2_1": "Pequeño",
    "BLK_2_2": "Impar",
    "BLK_2_3": "Par",
    "BLK_4_0": "Grande-Impar",
    "BLK_4_1": "Grande-Par",
    "BLK_4_2": "Pequeño-Impar",
    "BLK_4_3": "Pequeño-Par"
  },
  "version20": {
    "server": "Servicio al Cliente",
    "teacher": "Supervisor",
    "financer": "Finanzas",
    "planer": "Planificador",
    "chatPop": "Chat Ventana Flotante",
    "cancelText": "Cancelar Ventana Flotante",
    "betNumber": "Opciones de apuesta",
    "resendMsg": "El envío del mensaje falló. ¿Quiere volver a enviar el mensaje?",
    "delThisMsg": "Eliminar el mensaje",
    "delThisMemberAllMsg": "Eliminar todos los mensajes del miembro",
    "forbitThisMember": "Silenciado el miembro",
    "delThisMember": "Eliminar el miembro",
    "delTipModal": "Por favor seleccione la función a ser operada"
  },
  "version21": {
    "USDT_account": "Cuenta USDT",
    "closeSwapTip": "Seleccione la relación de cierre",
    "hasRecharge": "Recargado",
    "numberCoin": "Moneda Digital",
    "none": "-",
    "fastCharge": "Recarga Rápida",
    "bankTransfer": "Transferencia Bancaria",
    "setLegalTip": "Seleccione su moneda fiduciaria",
    "legalCoin": "Moneda Fiduciaria",
    "orderCreateTime": "Tiempo de Creación",
    "tradeOrderNumber": "Número de Transacción",
    "completeTime": "Tiempo de Finalización",
    "fastWithdraw": "Retiro Rápido",
    "bindCardTip1": "Por favor complete el nombre completo en su tarjeta de identificación válida, ya que no se puede modificar después del envío",
    "bindCardTip2": "Por favor complete la información de su tarjeta bancaria"
  },
  "saas": {
    "fastChargeType": "Recarga Rápida de Canales",
    "numberCoinChargeType": "Recarga de Moneda Digital",
    "humanChargeType": "Recarga Manual",
    "fastChannelWithdrawType": "Retiro Rápido de Canal",
    "numberCoinWithdrawType": "Retiro de Moneda Digital",
    "humanWithdrawType": "Retiro Manual",
    "fastChannelBackType": "Retorno de Canal Rápido",
    "numberChannelBackType": "Retorno de Moneda Digital",
    "betType": "Apuesta",
    "betProfitType": "Beneficio",
    "betEqualType": "Igual",
    "gameBackRewardType": "Reembolso de Juego",
    "swapBackRewardType": "Reembolso de Swap",
    "exchangeBackRewardType": "Reembolso de Spot",
    "tranInType": "Transferencia de Entrada",
    "tranOutType": "Transferencia de Salida",
    "chargeRewardType": "Bono de Recarga",
    "firstChargeRewardType": "Bono de Primer Depósito",
    "regRewardType": "Bono de Registro",
    "upGradeRewardType": "Bono de Promoción",
    "luckyRewardType": "Sorteo",
    "realNameRewardType": "Bono de Nombre Real",
    "betRewardType": "Bono de Apuestas",
    "dailyFirstChargeRewardType": "Bonificación Diaria por Primer Depósito",
    "upScoreType": "Aumento Manual",
    "downScoreType": "Disminución Manual",
    "unRecharge": "No Recargado",
    "canWithdrawBalance": "Saldo Retirable",
    "withPopTip1": "No se permite el retiro si hay flujo sin terminar.",
    "withPopTip2": "La parte del flujo sin terminar debe pagar {flowFee}% de la tarifa de flujo al retirar.",
    "alterBankCardNameTip": "Póngase en contacto con el servicio de atención al cliente si necesita cambiar su nombre",
    "arriveMoney": "Cantidad Recibida",
    "withdrawNum": "Cantidad de Retiro",
    "failReason": "Motivo del Fracaso",
    "tradeHash": "TXID",
    "waitCoin": "Esperando Préstamo",
    "zicang": "Activos",
    "otherMoney": "Estimación de Otros Activos",
    "moneyList": "Lista de Activos",
    "onlyShowSwap": "Solo Swap",
    "hideZeroMoney": "Ocultar Saldos Pequeños",
    "chargeFlowTip1": "El flujo inconcluso se incrementará en un {deposit}% del monto de la recarga de la cuenta USDT. Por ejemplo, si se recargan 1000 USDT, se agregará {depositValue} USDT del flujo sin terminar.",
    "chargeFlowTip2": "El flujo inconcluso se puede deducir a través de la apuesta efectiva del juego/opción binaria. Por ejemplo, si el flujo sin terminar es de 200 USDT y se genera una apuesta válida de 10 USDT en el juego, quedan 190 USDT en el flujo sin terminar.",
    "chargeRecord": "Registros de Depósito",
    "withdrawRecord": "Registros de Retiro"
  },
  "version22": {
    "verificationCode": "CAPTCHA",
    "userNameErr": "El formato del nombre de usuario es incorrecto. Ingrese de 6 a 32 letras o combinaciones alfanuméricas que pueda contener. y @",
    "tip": "Consejo",
    "because": "Razón",
    "no": "Número",
    "auditError": "Auditoría Fallida",
    "auditSuccess": "Auditoría Exitosa",
    "fundPassword": "El formato de la contraseña del fondo es incorrecto. Por favor ingrese 6 dígitos",
    "allEntrust": "Historial de Pedidos",
    "dealAmount": "Cantidad de Transacción",
    "watcherTeamForm": "Ver Informes de Equipo",
    "overview": "Visión General",
    "stopBet": "El pedido se ha cerrado en este período. Por favor deja de apostar",
    "balance": "Saldo Disponible",
    "exchangeOrderMinVolume": "El monto mínimo confiado es ",
    "exchangeOrderMaxVolume": "El monto máximo confiado es ",
    "BLK5MC": "Bloques de 5 minutos (Cadena Ascendente)",
    "RG5MC": "Rojo_Verde de 5 minutos (Cadena Ascendente)",
    "DS5MC": "Discos de 5 minutos (Cadena Ascendente)",
    "forbidTrade": "Se ha prohibido el comercio de este módulo comercial.",
    "minTurnoverNum": "La rotación mínima es ",
    "minSwapOpenNum": "El número mínimo de contratos es x",
    "maxSwapOpenNum": "El número máximo de contratos es x en este momento",
    "activity": "Actividad",
    "profitRecord": "Victorias",
    "service": "Servicio",
    "help": "Ayuda",
    "totalAssets": "Valor Total",
    "readAllLetterTip": "¿Está seguro de que desea configurar todos los mensajes como leídos?",
    "myTeam": "Mi equipo",
    "lv1": "Clase 1",
    "lv2": "Clase 2",
    "lv3": "Clase 3",
    "lvAll": "Clase 1/2/3",
    "calDataTime": "Tiempo Estadístico",
    "dataUpdateTip": "Hora de actualización de datos: {date}",
    "total": "Copias Totales",
    "lastTopUserName": "Nombre de Usuario superior",
    "humanAdd": "Aumento Manual",
    "humanSub": "Disminución Manual",
    "realNameTime": "Tiempo de Autenticación",
    "chargeTime": "Tiempo de Depósito",
    "reSubmit": "Reenviar",
    "goldMoney": "Cantidad Ganadora",
    "username": "Usuario",
    "myNum": "Mi código",
    "waitOpen": "Esperando el sorteo",
    "buyAmount": "Número de copias a comprar",
    "bugNum": "Compra las copias correspondientes",
    "gainCode": "Obtener el código de lotería",
    "totalNumOpenReward": "La lotería se abrirá después de que se agote el número total de copias.",
    "winnerGainAllReward": "El ganador se llevará todos los premios.",
    "teamLevel": "Nivel de Equipo",
    "SD_smoduleerialGame": "Discos",
    "RG_serialGame": "Rojo_Verde",
    "BLK_serialGame": "Bloques",
    "codeTip": "Depósito Dirección Código QR",
    "relateTopUser": "Superior",
    "lv1Num": "Clase 1 - Invitados",
    "lv2Num": "Clase 2 - Invitados",
    "lv3Num": "Clase 3 - Invitados",
    "singleLevelTxt": " Clase",
    "wrondPasswordCorrect": "El formato de la contraseña de inicio de sesión es incorrecto. Ingrese una combinación de 8-32 letras y números",
    "withdrawAccount": "Cuenta Retiro",
    "optionModeShutTip": "El modo de {minute} minutos está en mantenimiento actualmente",
    "optionModuleShutTip": "En mantenimiento",
    "gameClosed": "Juego Cerrado",
    "doBet": "APUESTA",
    "reAlterBindCard": "Su moneda fiduciaria no coincide con la moneda de su tarjeta bancaria. Actualice la información de la tarjeta bancaria a tiempo",
    "t2": "A partir de la suma de los valores de tiempo (UTC+0) de los últimos 50 registros de compra del juego antes del sorteo",
    "emailErr": "Por favor ingrese la dirección de correo electrónico correcta",
    "bigSymTip": "representar <Grande>",
    "smallSymTip": "representar <Pequeño>",
    "EvenSymTip": "representar <Par>",
    "OddSymTip": "representar <Impar>",
    "exchangeNum": "Usuarios Spot",
    "yesterdayAward": "Vctoria de Ayer",
    "yijixiaji": "Clase 1 - Invitado",
    "erjixiaji": "Clase 2 - Invitado",
    "sanjixiaji": "Clase 3 - Invitado",
    "p3": "<ins class='paramTitle'>Nivel del equipo</ins>Cuantos más invitados de clase 1 sean promovidos, mayor será el nivel del equipo y el reembolso que podrá disfrutar. El nivel del equipo se divide en LV1-LV6. Consulte la siguiente tabla para conocer las reglas de actualización, donde N es el número de invitados de clase 1 a los que se les ha cobrado.",
    "p4": "<ins class='paramTitle'>Proporción de reembolso de juego, opción, contrato de segundos, y diversión</ins>Cuando los invitados apuestan en juegos, contrato de segundos, opciones binaria, y diversión puede obtener un reembolso correspondiente al monto de su apuesta.",
    "p5": "<ins class='paramTitle'>Relación de reembolso de Spot y Swap</ins>Cuando los invitados realizan transacciones de spot y swap, puede obtener un reembolso correspondiente a su comisión por transacción. Si la comisión está en otras monedas que no sean USDT, el reembolso se convertirá a USDT según el tipo de cambio en tiempo real y se emitirá a su cuenta USDT.",
    "flag": "Carácter",
    "noZero": "El monto de la transferencia no puede ser 0",
    "networkTip": "Error de red, actualice la página y vuelva a cargar.",
    "inviteChargeTip": "La actualización del nivel del equipo depende de la cantidad de invitados de la clase 1 que se recargan con éxito.",
    "canWithdrawNum": "Aprovechar. Saldo",
    "withTip": "Por favor ingrese el monto del retiro",
    "withModalTip": "La tarifa de servicio incluye la tarifa de flujo y la tarifa de minería.",
    "totalUpNum": "Personas Totales",
    "chargeRangeTip": "El monto del depósito debe ser ",
    "jiaocheng": "Instrucción",
    "spare1": "Información Alternativa 1",
    "spare2": "Información Alternativa 2",
    "loadAllGroupMsg": "Todos los mensajes dentro de 7 días han sido cargados",
    "realMember": "Membresía completa",
    "testMember": "Miembro de prueba",
    "noBlankMsg": "No pueda enviar un mensaje en blanco",
    "maxLength1500": "El envío del mensaje falló porque el contenido es demasiado largo",
    "sendToMore": "El envío del mensaje falló porque las solicitudes son demasiado frecuentes",
    "countdown": "Cuenta Regresiva",
    "toLongRemark": "Demasiadas palabras, borre algunas palabras",
    "tip1": "<p>* Los datos están sujetos al tiempo estadístico. Los datos se actualizan cada hora. La hora de la última actualización es: {time}; Entre ellos, los usuarios de registro / usuarios de verificación / usuarios de primer depósito / monto del primer depósito se actualizan en tiempo real</p>",
    "tip2": "<p>* El saldo de USDT no tiene nada que ver con el tiempo estadístico y siempre muestra el saldo total actual del equipo</p>",
    "tip3": "* La cantidad de depósito/retiro/apuesta/ganancia/reembolso está sujeta al tiempo estadístico. Los datos se actualizan cada hora. La hora de la última actualización es: {time}",
    "tip4": "* Otros datos son independientes del tiempo estadístico, y siempre se muestran los últimos datos",
    "wrongImgType": "Formato de archivo no soportado",
    "addNewNotice": "Agregar",
    "noChoose": "No Seleccionado",
    "nullCoin": "/",
    "thankyou": "Gracias por participar",
    "oncemore": "Una Vez Más",
    "tip5": "El tipo de cambio puede cambiar, consulte la cantidad real",
    "xiugaichenggong": "Modificación exitosa, por favor inicie sesión nuevamente",
    "entrustNoZero": "La cantidad delegada no puede ser 0",
    "herTeam": "Su equipo",
    "betMoney": "Por favor ingrese el monto de la apuesta",
    addFilterMember : 'Añadir todos los miembros filtrados',
    delFilterMember : 'Eliminar todos los miembros filtrados',
    forbidFilterMember : 'Silenciar a todos los miembros filtrados',
    freeFilterMember : 'Desmudar a todos los miembros filtrados',
    theme1 : 'Tema del Juego 1',
    theme2 : 'Tema del Juego 2',
    theme3 : 'EX Tema 1',
    theme4 : 'EX Tema 2',
    themeTitle : 'Tema',
    maxTradeMoney: 'El volumen de negocio máximo es de '
  },
  "version23": {
    inviteCenter: "Centro de Invitaciones",
    search: 'Buscar',
    active: 'Acción',
    openDirect: 'Dirección del Resultado',
    profitAndLossAmount: 'G/P',
    levelMax: "Nivel máximo alcanzado",
    next: 'Página siguiente',
    prev: 'Página anterior siguiente',
    secondRecord : 'Registros de Contratos de Segundos',
    entrusting : 'En consignación',
    secondReward : 'Ganador de Contrato de Segundos',
    secondBet : 'Apuesta de Contrato de Segundos',
    secondProfit : 'Beneficio de Contrato de Segundos',
    RD100N : 'Al azar 100',
    secondNum : 'Usuarios de Contrato de Segundos',
    rebackTip : 'El reembolso se pagará cada hora, espere pacientemente.',
    secondOption : 'Contrato de Segundos',
    qiquan : 'Opción',
    exportBtn : 'Exportar',
    "SB1M": "Aeronave de 1 minuto",
    "SB3M": "Aeronave de 3 minuto",
    "SB5M": "Aeronave de 5 minuto",
    "SBTM": "Aeronave de 10 minuto",
  },
  version24 : {
    goldBoom : 'Pisar una mina',
    'MS' : 'Barredor de minas',
    spRecord : 'Registros de Deportivos-Ponymuah',
    "QT1M": "SICBO de 1 minuto",
    "QT3M": "SICBO de 3 minuto",
    "QT5M": "SICBO de 5 minuto",
    "QTTM": "SICBO de 10 minuto",
    "KN1M": "KENO de 1 minuto",
    "KN3M": "KENO de 3 minuto",
    "KN5M": "KENO de 5 minuto",
    "KNTM": "KENO de 10 minuto",
    "electronRecord": "Registros de Arcade-Evolution",
    "LOT1M": "LOTTO de 1 minuto",
    "LOT3M": "LOTTO de 3 minuto",
    "LOT5M": "LOTTO de 5 minuto",
    "LOTTM": "LOTTO de 10 minuto",
    'LOTW3' : 'LOTTO',

    "RLT1M": "Ruleta de 1 minuto",
    "RLT3M": "Ruleta de 3 minuto",
    "RLT5M": "Ruleta de 5 minuto",
    "RLTTM": "Ruleta de 10 minuto",
    "LK281M": "Suerte 28 de 1 minuto",
    "LK283M": "Suerte 28 de 3 minuto",
    "LK285M": "Suerte 28 de 5 minuto",
    "LK28TM": "Suerte 28 de 10 minuto",
    "liveRecord": "Registros de Directo-Ezugi",
    evoLiveRecord : 'Registros de  Directo-Evolution',
    pbRecord : 'Registros de Deportivos-PinnacleBet',
    LIMBO : 'LIMBO',
    HILO : 'HILO',
    'FSLOT' : 'Frutas',
    'RULTI' : 'Rueda',
    'RULTI_2-075_0' : 'Negro',
    'RULTI_3-176_0' : 'Rojo',
    'RULTI_5-4_0' : 'Azul',
    'RULTI_54_0' : 'Verde',
	'RB' : 'Rojo_Negro',
	'RB_2-142_0' : 'Rojo',
	'RB_15_0' : 'Blanco',
	'RB_2-142_1' : 'Negro',
	
    'FST' : '4 Estrellas',
    'FST1M' : '4 Estrellas de 1 minuto',
    'FST5M' : '4 Estrellas de 5 minuto',
    'FSTTM' : '4 Estrellas de 10 minuto',
    'FST1D' : '4 Estrellas de 1 día',

    'TS' : '3 Estrellas',
    'TS1M' : '3 Estrellas de 1 minuto',
    'TS5M' : '3 Estrellas de 5 minuto',
    'TSTM' : '3 Estrellas de 10 minuto',
    'TS1D' : '3 Estrellas de 1 día',

    'TWLT1M' : 'Gran Lotto de 1 minuto',
    'TWLT5M' : 'Gran Lotto de 5 minuto',
    'TWLTTM' : 'Gran Lotto de 10 minuto',
    'TWLTW2' : 'Gran Lotto',
    'TWLT' : 'Gran Lotto',


    'SPLT1M' : 'Súper Lotto de 1 minuto',
    'SPLT5M' : 'Súper Lotto de 5 minuto',
    'SPLTTM' : 'Súper Lotto de 10 minuto',
    'SPLTW2' : 'Súper Lotto',

    'SPLT' : 'Súper Lotto',
  },
  version25 : {
    sport : 'Deportes-Ponymuah',
    live :'En directo-Ezugi',
    electron : 'Arcade-Evolution',

    sport_index : 'Deportes',
    live_index :'En directo',
    electron_index : 'Arcade',

    PG : 'Arcade-PocketGame',
    PP : 'Arcade-PragmaticPlay',
    "sportsNum": "Usuarios de Deportivos",
    "sportsAward": "De Deportes Ganar",
    "sportsBet": "Apuestas de Deportivas",
    "sportsProfit": "Beneficios de Deportivos",

    electronNum : 'Usuarios de Arcade',
    electronAward : 'De Arcade Ganar',
    electronBet:'Apuestas de Arcade',
    electronProfit : 'Beneficios de Arcade',

    liveNum : 'Usuarios de directo',
    liveAward : 'De directo Ganar',
    liveBet:'Apuestas de directo',
    liveProfit : 'Beneficios de directo',
		
	Num : 'Usuarios',
    Award : 'Ganar',
    Bet:'Apuestas',
    Profit : 'Beneficios',

    ourGame : 'Juegos Originales',
    "t1": "Toneladas de Juegos, Todo en Uno",
    "t2": "La principal plataforma de juegos del mundo",
  },
  version26 : {
    liveTeamReport : 'Informe del equipo en tiempo real',
    tip2 : `Solo se puede elegir el tiempo en las últimas 24 horas`
  },
  version27 : {
    PGrecord : 'Registros de Arcade-PocketGame',
    PPRecord : 'Registros de Arcade-PragmaticPlay',

  }
}