<template>
  <el-dialog :width="dialogWidth" :title="title" :visible="visible" @close="$emit('update:visible', false)" :class="{'headerBottomLine': isShowHeaderBottomLine}">
    <slot></slot>
  </el-dialog>
</template>

<script>
  export default {
    name: "CustomDialog",
    props: {
      title: {
        type: String,
        default: '温馨提示'
      },
      visible: {
        type: Boolean,
        default: false
      },
      isShowHeaderBottomLine: {
        type: Boolean,
        default: true
      },
      dialogWidth: {
        type: String,
        default: '540px'
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-dialog {
    background-color: transparent;
  }
  /deep/.el-dialog__header {
    background-color: rgba(28, 29, 32, 1);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    .el-dialog__title {
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      line-height: 22px;

    }
    .el-dialog__close {
      width: 16px;
      height: 16px;
      color: rgba(255, 255, 255, 1);
    }
}
/deep/.el-dialog__body {
    background-color: rgba(28, 29, 32, 1);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0 10px 10px 10px;
  }

  .headerBottomLine {
    /deep/.el-dialog__header {
      border-bottom: 1px solid rgba(64, 65, 68, 1);
    }
  }
</style>