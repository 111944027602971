import Vue from 'vue'

const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {}
const userSetting = localStorage.getItem('userSetting') ? JSON.parse(localStorage.getItem('userSetting')) : {}

export const state = Vue.observable({
  authorization: 'Basic cm9ja2V0X3dlYjpyb2NrZXRfd2Vi',
  siteConfig: {},
  userData: userData,
  userSetting: userSetting,
  // USDT 统一精度
  numberScale: 3,
  // 充值状态
  chargeStatus: {
    "-1": '全部',
    0: '未充值',
    1: '已充值',
  },
  optionRecordTimeMap: {
    ONE_MIN: "1" + '分钟',
    FIVE_MIN: "5" + '分钟',
    FIFTEEN_MIN: "15" + '分钟',
  },
  // 期权订单状态
  optionRecordOrderStatus: {
    0: '未开奖',
    1: '已中奖',
    "-1": '未中奖',
    2: '无涨跌',
    "-2": '已撤单',
  },
  optionRecordCalResultMap: {
    RISE: '涨',
    FALL: '跌',
    STRAIGHT: '无涨跌'
  },
  gameRecordStatus: {
    6: '已中奖',
    7: '未中奖',
    3: '未开奖',
    4: '已撤单',
  },
  SD_betOptionMap: {
    1111: '4红0白',
    1110: '3红1白',
    "0000": "0红4白",
    1000: "1红3白",
  },
  // 交易对列表
  coinList: [],
  // 游戏列表
  gameList: [],
  // 语言列表
  langList: [],
  // 当前所选语言
  lang: '',
  backEndMap: {
    "en-US": "en-US",
    "zh-TW": "zh-TW",
    "zh-CN": "zh-CN",
    ja: "ja",
    th: "tha",
    vi: "vi",
    es: "es-US",
    id: "idn",
    pt: "pt",
    tr: "tr",
  },
  isUnderNoAccess : false,
  maintainData : {},
  noAccessData : false
});

export const mutations = {
  changeAuthorization(val) {
    state.authorization = val
  },
  changeUserData(val) {
    state.userData = val
  },
  setUserSetting(val) {
    state.userSetting = val
  },
  changeCoinList(val) {
    state.coinList = val
  },
  changeGameList(val) {
    state.gameList = val
  },
  changeSiteConfig(val) {
    state.siteConfig = val
  },
  setLangList(val) {
    state.langList = val
  },
  setLang(val, _this) {
    state.lang = val
    console.log('store state lang func ',state.lang)
    _this.$i18n.locale = val
    localStorage.setItem('lang', val);
    document.querySelector("html").setAttribute("lang", val);
  },

  setLangStatus(_this) {
    state.chargeStatus = {
      "-1": _this.$t("pageTitle.quanbu"),
      0: _this.$t("saas.unRecharge"),
      1: _this.$t("version21.hasRecharge"),
    }
    state.optionRecordTimeMap = {
      ONE_MIN: "1" + _this.$t("chart.minute"),
      FIVE_MIN: "5" + _this.$t("chart.minute"),
      FIFTEEN_MIN: "15" + _this.$t("chart.minute"),
    }
    state.optionRecordOrderStatus = {
      0: _this.$t("version3.unOpen"),
      1: _this.$t("version3.hasBingGo"),
      "-1": _this.$t("version3.unBingGo"),
      2: _this.$t("version17.equal"),
      "-2": _this.$t("pageTitle.yichexiao"),
    }
    state.optionRecordCalResultMap = {
      RISE: _this.$t("version17.up"),
      FALL: _this.$t("version17.down"),
      STRAIGHT: _this.$t("version17.equal"),
    }
    state.gameRecordStatus = {
      6: _this.$t("version3.hasBingGo"),
      7: _this.$t("version3.unBingGo"),
      3: _this.$t("version3.unOpen"),
      4: _this.$t("pageTitle.yichexiao"),
    }
    state.SD_betOptionMap = {
      1111: _this.$t("version9.FourRed"),
      1110: _this.$t("version9.ThreeRedOneWhite"),
      "0000": _this.$t("version9.FourWhite"),
      1000: _this.$t("version9.OneRedThreeWhite"),
    }
  },
};