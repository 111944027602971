import axios from 'axios'
import Qs from 'qs'

import { Message } from 'element-ui'
import {state, mutations} from "@/store";
import router from '@/router'

let timeZone = new Date().getTimezoneOffset() / 60 * -1

axios.defaults.timeout = 10000

// 请求拦截
axios.interceptors.request.use((config) => {
  if(state.userData.access_token)config.headers["Blade-Auth"] = state.userData.access_token || ''
  console.log(state.lang,'state.lang')
  config.headers["Accept-Language"] = state.lang;
  config.headers["user_type"] = "rocket";
  config.headers["Authorization"] = state.authorization || '';

  config.headers['Offset-Id'] = timeZone>=0?'+'+timeZone : timeZone

  return config
}, error => {
  return Promise.error(error)
})

// 响应拦截
axios.interceptors.response.use(response => {
  if (response.status === 200 /*&& response.data.code === 200*/) {
    if (+response.data.code == 444) {
      //后台维护中
      state.maintainData = response.data.data;
    }
    else if(+response.data.code == 40301){
      state.isUnderNoAccess = true;
      state.noAccessData = response.data.data

     /*  router.push({
        path: '/noAccess',
        query: { ipAddress: response.data.data.ip || '--' },
      }).catch((err) => {
        console.log(err);
      }); */
    }
    return Promise.resolve(response)
  } else {
    Message.error(response.data.msg)
    return Promise.reject(response)
  }
}, error => {
  if (error.response.status) {
    switch (error.response.status) {
      // 401登录过期
      case 401:
        localStorage.setItem('userData', '')
        mutations.changeUserData({});
        router.replace({
          path: '/login'
        });
        // Message.error(error.response.data.msg)
        break;

   
   
      default:
        // Message.error(error.response.data.msg)
    }
    return Promise.reject(error.response.data.msg)
  }
})

export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, Qs.stringify(params))
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export default axios;