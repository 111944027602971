<template>
  <svg class="svg-icon" aria-hidden="true"
       :class="'icon-'+iconClass"
  >
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
  export default {
    name: "iconSvg",
    props : {
      iconClass : {
        type : String,
        required : true
      },
    },
    computed : {

      iconName(){
        return `#icon-${this.iconClass}`
      },
    }
  }
</script>

<style scoped>
  .svg-icon{
    width : 1em;
    height : 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
  svg[spin] {animation: iconpark-spin 1s infinite linear;}
  svg[spin][rtl] {animation: iconpark-spin-rtl 1s infinite linear;}
  svg[rtl] {
    transform: scaleX(-1);
  }
  @keyframes iconpark-spin {
    0% { -webkit-transform: rotate(0); transform: rotate(0);} 100% {-webkit-transform: rotate(360deg); transform: rotate(360deg);}
  }
  @keyframes iconpark-spin-rtl {
    0% {-webkit-transform: scaleX(-1) rotate(0); transform: scaleX(-1) rotate(0);} 100% {-webkit-transform: scaleX(-1) rotate(360deg); transform: scaleX(-1) rotate(360deg);}
  }
</style>