import Vue from 'vue';
import VueRouter from 'vue-router';
import {state} from "@/store";
// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes =[
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('@/page/Index/Index.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/reg',
    redirect: '/index',
  },
  {
    path: '/Login',
    component: () => import('@/page/Login/Login.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/noAccess',
    component: () => import('@/page/noAccess.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/404',
    component: () => import('@/page/404.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/Main',
    component: () => import('@/layout/Home.vue'),
    children: [
      // {
      //   path: '/',
      //   redirect: '/inviteCenter'
      // },
      {
        path: '/inviteCenter',
        component: () => import('@/page/Invite/InviteCenter.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/gameRecord',
        component: () => import('@/page/Invite/GameRecord.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/spRecord',
        component: () => import('@/page/Invite/spRecord.vue'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/electronRecord',
        component: () => import('@/page/Invite/electronRecord.vue'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/pgRecord',
        component: () => import('@/page/Invite/pgRecord.vue'),
        meta: {
          requiresAuth: true
        }
      },


      {
        path: '/lowerPPRecord',
        component: () => import('@/page/Invite/lowerPPRecord.vue'),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: '/evoLiveRecord',
        component: () => import('@/page/Invite/evoLiveRecord.vue'),
        meta: {
          requiresAuth: true
        }
      },


      {
        path: '/pbRecord',
        component: () => import('@/page/Invite/pbRecord.vue'),
        meta: {
          requiresAuth: true
        }
      },
      
      {
        path: '/liveRecord',
        component: () => import('@/page/Invite/liveRecord.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/myTeam',
        component: () => import('@/page/Invite/MyTeam.vue'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/myTeam/:memberId',
        component: () => import('@/page/Invite/MyTeamDetail.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/optionRecord',
        component: () => import('@/page/Invite/OptionRecord.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/secondRecord',
        component: () => import('@/page/Invite/secondRecord.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/teamReportForm',
        component: () => import('@/page/Invite/TeamReportForm.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/liveTeamReport',
        component: () => import('@/page/Invite/liveTeamReport.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/transactionDetail',
        component: () => import('@/page/Invite/TransactionDetail.vue'),
        meta: {
          requiresAuth: true
        }
      },
    ]
  },
];

const router =new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to, 'to');
  if (to.meta.requiresAuth && !Object.keys(state.userData).length) {
    next({
      path: '/login'
    })
  } else {
    next()
  }
})

export default router