module.exports = {
    "nav": {
        "shouye": "Ana Sayfa",
        "hanqing": "Piyasalar",
        "jiaoyi": "Ticaret",
        "heyue": "Swap",
        "wode": "Ben"
    },
    "common": {
        "login": "Giriş Yapma",
        "register": "Üye Olma",
        "loginregister": "Giriş Yapma / Üye Olma",
        "logout": "Çıkış Yapma",
        "tip": "İpuçları",
        "delete": "Silmek istediğinize emin misiniz?",
        "set": "Ayarlar",
        "update": "Değiştir",
        "more": "Daha fazla",
        "loading": "Yükleniyor",
        "zanwu": "Kayıt Yok",
        "quxiao": "Vazgeç",
        "ok": "Tamam",
        "noyanzhengma": "Lütfen doğru doğrulama kodunu girin",
        "clearStorage": "Önbelleği Temizle",
        "telErr": "Lütfen doğru cep telefonu numarasını girin",
        "T404": "Platform güncelleniyor ve erişim daha sonra geri yüklenecek"
    },
    "loginPage": {
        "mima": "Şifre",
        "passwordTxt": "Lütfen şifreyi giriniz"
    },
    "homePage": {
        "charge": "Para yatırma",
        "pickup": "Para çekme",
        "exchange": "Spot",
        "promotionaward": "İndirim"
    },
    "pageTitle": {
        "gonggaoxiangqing": "Duyuru",
        "wodezichang": "Bakiyeler",
        "bangzhuxiangqing": "Detaylar",
        "fundpwd": "Fon Şifresi",
        "yuyanshezhi": "Dil",
        "huazhuang2": "Aktarma",
        "renzheng": "Kimlik Doğrulama",
        "chooseAlterPhoneType": "Cep numarası",
        "chooseAlterEmailType": "E-posta",
        "letterList": "Bildirimler",
        "appDownload": "Uygulamayı İndir",
        "buy": "ALMAK",
        "sell": "SATMAK",
        "cexiao": "İptal etme",
        "time": "Zaman",
        "type": "Tür",
        "jiage": "Fiyat",
        "weituoliang": "Tutar",
        "changjiao": "Yürütüldü",
        "weituoe": "Ödeme",
        "over": "Hepsi yüklendi",
        "weituojia": "Emanet Edilen Fiyat",
        "weituoer": "Toplam Tutar",
        "quedingchedan": "İptal edeceğinizden emin misiniz?",
        "chedaning": "Sipariş iptali",
        "jiaoyidui": "Ticaret çifti",
        "fangxiang": "Taraf",
        "chongzhi": "Sıfırla",
        "xianjia": "Limit fiyat",
        "shijia": "Market",
        "plan": "Tetikleme",
        "quanbu": "Herşey",
        "xianjiaweituo": "Limit emri",
        "yichexiao": "İptal edildi",
        "time1": "Başlangıç zamanı, bitiş zamanından önce olmalıdır",
        "time2": "Başlangıç zamanı, bitiş zamanından önce olmalıdır",
        "time3": "Bitiş zamanı, geçerli saatten önce olmalıdır",
        "cedancg": "İptal başarılı oldu",
        "cedansb": "İptal edilemedi",
        "mairujiage": "Birim fiyat",
        "yongxu": "SWAP",
        "zhanghu": "Eşitlik",
        "yugu": "EST Tasfiye Fiyatı",
        "baozheng": "Marj Oranı",
        "kaicang": "Pozisyon aç",
        "baocang": "Zorla Tasfiye",
        "pincang": "Kapalı Pozisyon",
        "zhanghu2": "Hesap",
        "pingduo": "Kapat Uzun",
        "kaiduo": "Açık Uzun",
        "pingkong": "Kapat Kısa",
        "kaigong": "Açık Kısa",
        "cufajia": "Tetik Fiyatı",
        "zuiyou": "En iyi fiyat",
        "zhang": "Devam",
        "keyong": "Mevcut",
        "kekaiduo": "Maks. L",
        "kekaikong": "Maks. S",
        "duocang": "Maks (L)",
        "kongcang": "Maks (S)",
        "kanzhang": "Artışı",
        "kandie": "Düşüşü",
        "shuliang": "Tutar",
        "chicang": "Pozisyonlar",
        "dangqianweituo": "Açık siparişler",
        "lishiweituo": "Tarih",
        "shouyi": "Gelir",
        "shouyilv": "Getiri Oranı",
        "gangganbeishu": "Kaldıraç",
        "chicangliang": "Durum",
        "kepingliang": "Uygun pozisyon",
        "kaichangjun": "Açık Fiyat",
        "zuixinjia": "Son fiyat",
        "dangqianbaozheng": "Cur Marjı",
        "duotou": "Uzun",
        "kongtou": "Kısa",
        "baozhengjin": "Marj",
        "weituoshuliang": "Emanet Edilen Tutar",
        "jiesuanweik": "Uzlaşma kar ve zararı",
        "chengjiaojia": "Nihai Fiyat",
        "shouxufei": "Hizmet bedeli",
        "xiugaichenggong": "Başarıyla değiştirildi"
    },
    "views": {
        "wangjiPass": "Şifreyi unuttun?",
        "confirmLogOut": "Oturumu kapattığınızdan emin misiniz?",
        "personalCenter": "Kullanıcı Merkezi",
        "shezhi": "Başarıyla ayarla",
        "yuanyouxiang": "Orijinal e-posta doğrulama kodu",
        "newyouxiang": "Yeni e-posta doğrulama kodu",
        "fsyouxiang": "Doğrulama kodunu gönder",
        "xyouxiang": "Yeni E-posta",
        "loginPass": "Giriş şifresi",
        "baocun": "Kaydet",
        "fschenggong": "Başarıyla gönderildi",
        "inputyouxiang": "Lütfen yeni e-posta adresinin doğrulama kodunu girin",
        "inputOld": "Lütfen eski e-posta doğrulama kodunu girin",
        "inputNew": "Lütfen yeni e-posta adresinin doğrulama kodunu girin",
        "inputPass": "Lütfen giriş şifrenizi girin",
        "OldPass": "Eski Giriş Şifresi",
        "inputOldPass": "Lütfen eski giriş şifrenizi girin",
        "NewPass": "Yeni Giriş Şifresi",
        "inputNewPass": "Lütfen yeni giriş şifrenizi girin",
        "queren": "Şifreyi Onayla",
        "email": "E-posta doğrulama kodu",
        "liangciPass": "Girilen iki şifre eşleşmiyor",
        "shuruCode": "Lütfen doğrulama kodunu girin",
        "OldZJ": "Eski Fon Şifresi",
        "inputOldZJ": "Lütfen eski fon şifrenizi giriniz",
        "NewZJ": "Yeni Fon Şifresi",
        "inputNewZJ": "Lütfen yeni fon şifrenizi giriniz",
        "augeren": "Kişisel bilgi",
        "aushang": "Kimlik Kartı Yükle",
        "aushenghe": "Gözden geçirme",
        "name": "Ad Soyad",
        "inputName": "Lütfen adınızı girin",
        "huzhao": "TC Kimlik Numarası",
        "inputhuzhao": "Lütfen TC kimlik numarasını girin",
        "next": "Sonra",
        "uphuzhao": "Kimlik kartınızın fotoğrafını yükleyin",
        "shouhuzhao": "Sertifikalı kişilerin fotoğraflarını yükleyin",
        "tijiao": "Gönder",
        "tip1": "1. Lütfen kimliğinizin süresinin olmadığından emin olun",
        "tip2": "2. İmzanın içeriği el yazısı {hrefArry}, kişisel imza ve güncel tarihi içerir",
        "tip3": "3. Lütfen fotoğrafların ve kişisel imzaların açıkça göründüğünden emin olun",
        "shentijiao": "Bilgi gönderildi, lütfen inceleme için bekleyin",
        "fanhui": "Dönüş",
        "bangding": "Bağla",
        "inputEmail": "Lütfen e-postanızı girin",
        "inputPhone": "Lütfen telefon numarasını girin",
        "kefu": "Lütfen müşteri hizmetlerine başvurun"
    },
    "list": {
        "wufaxiugia": "Pozisyonlarınız veya yapılmamış emirleriniz olduğunda birden fazla değişiklik yapamazsınız",
        "submittip1": "Fonlarınızın güvenliğini sağlamak için lütfen önce gerçek ad doğrulaması yapın",
        "submittip2": "Fonlarınızın güvenliğini sağlamak için lütfen önce fon şifresini belirleyin",
        "shuruweituo": "Lütfen sipariş fiyatını giriniz",
        "shuruweituoliang": "Lütfen emanet edilen tutarı giriniz",
        "dayukekai": "Mevcut sipariş tutarı, açık uzun tutardan daha büyük",
        "dayuduocang": "Mevcut emir hacmi, kapatılabilecek uzun pozisyonların sayısından fazla",
        "dayukekaikong": "Mevcut sipariş tutarı, kısa açılabilecek miktardan fazla",
        "dayukongcang": "Mevcut emir hacmi, kısa pozisyondan daha büyük kapatılabilir",
        "shuruchufa": "Lütfen bir tetikleyici fiyat girin",
        "zhangfubang": "Sıralama",
        "liang": " Cilt",
        "safar": "Safari'de aşağıdaki düğmeyi tıklayın",
        "kuaisuanzhuang": "[Ana Ekrana Ekle]'yi seçin",
        "noAccount": "Henüz hesap yok mu?",
        "LoginReg": "Kayıt olma",
        "butigongfuwu": "Lütfen IP veya yerel hizmetinizin şu anda kullanılamadığını anlayın",
        "renzheng1": "Doğrulandı",
        "renzheng2": "Doğrulanamadı",
        "renzheng3": "İnceleme altında",
        "renzheng4": "Doğrulayın",
        "shenheing": "İnceleniyor, lütfen sabırla bekleyin",
        "inputzaicimima": "Lütfen şifreyi tekrar girin",
        "xuantian": "Lütfen davet kodunu girin (isteğe bağlı)",
        "yuedu": "Okudum ve kabul ediyorum ",
        "yonghuxieyi": "Kullanıcı Hizmet Sözleşmesi",
        "hasAccount": "Zaten kayıtlı?",
        "denglu": "Giriş yapma",
        "gouxuanxieyi": "Lütfen Kullanıcı Hizmet Sözleşmesini okuyun ve kontrol edin",
        "inputzijinmima": "Lütfen fon şifrenizi giriniz",
        "yijianfankui": "Geri bildirim",
        "gao": "Yüksek",
        "di": "Düşük",
        "shengdu": "Sipariş defteri",
        "chengjiao": "Dolu",
        "zixuan1": "Favorilerden kaldırıldı",
        "zixuan2": "Seçim iptal edilemedi",
        "zixuan3": "İsteğe bağlı başarıyla ekle",
        "zixuan4": "İsteğe bağlı eklenemedi",
        "jiaoyie": "Toplam işlem",
        "chengjiaozonger": "Toplam ciro",
        "chengjiaojun": "Ortalama fiyat",
        "chengjiaoliang": "İşlem sayısı",
        "bigmairu": "Ticarete konu olan maksimum miktar aşıldı ",
        "weituochenggong": "Başarılı şekilde takas edildi",
        "weituoshibai": "Ticaret başarısız oldu",
        "yue": "Denge",
        "bizhong": "Para birimi",
        "huazhuanshuliang": "Transfer miktarı",
        "inputhzsl": "Lütfen transfer tutarını girin",
        "bunengweifu": "Aktarım tutarı 0'dan büyük olmalıdır",
        "dayuhuazhuan": "Transfer miktarı, hesabınızdaki mevcut miktardan fazla olamaz",
        "huazhuanchenggong": "Başarıyla aktarıldı",
        "huazhuangshibai": "Aktarılamadı"
    },
    "user": {
        "guizeshuoming": "Tüzük",
        "shenheweitongguo": "İnceleme başarısız oldu",
        "shiming": "Lütfen kimlik doğrulamasını tamamlayın",
        "fuzhichenggong": "Başarıyla Kopyalandı",
        "frozen": "Siparişlerde",
        "zhanghuquanyi": "Eşitlik",
        "baozhenjin": "Serbest marj",
        "jiaoyishijian": "İşlem saati"
    },
    "tradePage": {
        "CUSTOM": "Favoriler"
    },
    "chart": {
        "minute": "Dakika"
    },
    "charge": {
        "congbi": "Mevduat Adresi",
        "baocun": "QR Kodunu Kaydet"
    },
    "heyuePage": {
        "yijianpingcang": "Flash Close",
        "copyerr": "Kopyalanamadı",
        "pincang": "Kapalı pozisyonun sayısı 0 olamaz."
    },
    "yaoqin": {
        "zuce": "Kayıt Tarihi",
        "showPersonInfo": "Profilinize bakın",
        "myinvitelink": "Davet Bağlantısı",
        "my_column4": "Seviye",
        "yaoqing": "Davet",
        "dengji": "Seviye İlerleme",
        "mubiao": " Hedef",
        "zong": "Toplam İndirimler",
        "jiaoyi": "İşlemler",
        "wode": "Davet Kodu",
        "huodong": "Etkinlik Bonusu"
    },
    "version3": {
        "hash": "Hash Değeri",
        "betTip": "Her 10 dakikada bir ilk ETH bloğunun karma değerinin son bitini tahmin edin, 0-7 Küçük, 8-f Büyük, 0, 2, 4, 6, 8, a, c ve e Çift, 1, 3 , 5, 7, 9, b, d ve f Tektir.",
        "betTip2": "Hash değerinin son bitini tahmin edin, 0-7 Küçük, 8-f Büyük, 0, 2, 4 ,6, 8, a, c ve e Çift, 1, 3, 5, 7, 9, b , d ve f Tektir.",
        "betMoneyTip": "Bahis başına miktar",
        "doBet": "Şimdi Bahis Yap",
        "issue": "No.",
        "blockHigh": "Blok Yüksekliği",
        "blockTime": "Blok zamanı",
        "hash2": "Hash Değeri",
        "gameRecord": "Oyun Kayıtları",
        "betTime": "Bahis Süresi",
        "pickUpAddress": "Para Çekme Adresi",
        "arriveAccountNum": "Alınan Tutar",
        "remark": "Açıklama",
        "contachService": "Müşteri hizmetleriyle iletişim kurun",
        "totalBetMoney": "Toplam siparişler",
        "unOpen": "piyango yok",
        "maintain": "Bakımda",
        "hasBingGo": "kazandı",
        "unBingGo": "kazanmadı",
        "outOfBalance": "Denge yeterli değil",
        "chooseBetNumTip": "Lütfen bahis seçeneğini seçin",
        "chooseBetMoney": "Lütfen bahis başına miktarı girin",
        "minBetAmount": "Bahis başına minimum miktar: ",
        "maxBetAmount": "Bahis başına maksimum miktar: ",
        "chooseWithDrawAddressTip": "Lütfen bir adresi seçin",
        "inputRemarkTip": "Lütfen para çekme adresinizi girin",
        "confirmOrder": "Siparişi onayla",
        "manji": "Maksimum VIP seviyesine ulaşıldı",
        "selWithAddress": "Lütfen bir para çekme adresi seçin",
        "withNumTip": "Para çekme miktarı olmalıdır: ",
        "unLiuShui": "Bitmemiş Akış",
        "withdrawChannel": "Para Çekme Kanalı"
    },
    "version4": {
        "game": "Oyun",
        "baocangIntro": "{account} takas hesabınızın özsermayesi {date} tarihinde 0 USDT, y, marj oranı ≤ 0 ve zorunlu tasfiye tetiklenecek.",
        "geshi": "Lütfen doğru miktarı girin",
        "mingc": "İşlem sayısı",
        "zhangd": "Fiyat yükselir ve düşer",
        "quanqiu": "Global",
        "yiliu": "A-team",
        "yinhang": "Security"
    },
    "version5": {
        "rechargeWay": "Para Yatırma Kanalı",
        "inputChargeNum": "Lütfen para yatırma tutarını girin",
        "receivePeople": "Alacaklı",
        "bankCard": "Banka kartı",
        "bank": "Banka",
        "bankNumber": "Banka hesabı",
        "bankProvince": "Bulunduğu İl",
        "bankCity": "Bulunduğu Şehir",
        "bankBrancher": "Banka şubesi",
        "rengong": "Manuel Yatırma ve Çekme"
    },
    "version7": {
        "p6": "Lütfen önce banka kartınızı bağlayın",
        "jinxingzhong": "Ticaret",
        "yiwancheng": "Tamamlandı",
        "shibai": "İşlem başarısız",
        "nichen": "Kullanıcı adı",
        "p8": "Tek İşlem Kotası",
        "username": "Lütfen kullanıcı adını girin"
    },
    "version8": {
        "noArrive0": "Alınan tutar 0 olamaz",
        "xuantian": "Lütfen davetiye kodunu girin",
        "result": "Sonuçlar",
        "color": "Renk",
        "hlRule": "0-9 arasındaki sayıyı tahmin edin. 1, 3, 5, 7, 9 Yeşil, 0, 2, 4, 6, 8 Kırmızı, 0 ve 5 de Mor."
    },
    "Transfer": {
        "FriendsReport": "Takım Raporu",
        "jintian": "Bugün",
        "zuotian": "Dün",
    'qiantian' : 'Dün önceki gün',

        "benzhou": "Bu hafta",
        "shangzhou": "Geçen hafta",
        "benyue": "Bu ay",
        "shangyue": "Geçen ay",
        "zuce": "Kayıt Kullanıcıları",
        "shoucong": "İlk Para Yatıran Kullanıcılar",
        "chongzhi": "Para Yatırma Kullanıcılar",
        "tixian": "Para Çekme Kullanıcıları",
        "heyue": "Kullanıcıları Değiştir",
        "youxi": "Oyun Kullanıcıları",
        "touzu": "Oyun Bahisleri",
        "zhongjiang": "Oyun Kazanma",
        "shimingrenshu": "Doğrulama Kullanıcıları",
        "status_1": "İnceleme altında",
        "status_3": "Başarısız",
        "status_4": "Başarı",
        "jilu": "Kayıt"
    },
    "version9": {
        "SD1MRule": "Renkli disklerin sayısını tahmin edin (kırmızı ve beyaz). Aynı renkteki disklerin sayısı çift, aynı renkteki disklerin tek sayısı ise Tek'tir.",
        "FourRed": "4-Kırmızı/0-Beyaz",
        "ThreeRedOneWhite": "3-Kırmızı/1-Beyaz",
        "FourWhite": "0-Kırmızı/4-Beyaz",
        "OneRedThreeWhite": "1-Kırmızı/3-Beyaz"
    },
    "version10": {
        "titMode3": "Zincir üzerindeki işlemin geri çekilirken işlenmesini sağlamak için madencilik ücretini ödemesi gerekiyor. Mevcut madencilik ücreti {minTxFee} USDT'dir.",
        "onGame": "Devam ediyor",
        "tuihui": "İade",
        "hasJump": "Paraşütle atladı",
        "player": "Oyuncular",
        "money": "Tutar",
        "jump": "Paraşütle atlama",
        "jumpMulti": "Çoklu Nakit Çıkışı",
        "betMoney": "Bahis Tutarı",
        "autoJumpTip": "Otomatik Cashout Çoklu (Opsiyonel)",
        "p1": "Minimum paraşütle atlama çarpanı aşağıdakilerden büyük olmalıdır:",
        "p2": "Maksimum paraşütle atlama kat sayısı:",
        "autoJump": "Otomatik Nakit Çıkışı",
        "boomValue": "Patlama noktası çarpanı",
        "crash": "Çöktü",
        "noRepeatOrder": "Lütfen tekrar tekrar bahis yapmayın",
        "jumpSuccess": "Nakde çevrildi",
        "tip1_plan": "1. Otomatik para çekme çoklu opsiyoneldir. Doldurduktan sonra, uçak ilgili kata ulaştıktan sonra otomatik olarak para çekeceksiniz veya uçak çarpmadan önce manuel olarak para çekebilirsiniz; Doldurmadıysanız, uçak düşmeden önce yine de manuel olarak para çekebilirsiniz.",
        "tip2_plan": "2. Cashout yapan oyuncuların kârının %{amount} kadarını alacağız.",
        "dizhi": "Lütfen doğru adresi girin"
    },
    "version11": {
        "trend": "Trendler",
        "goldenBroad": "kazananlar",
        "broadTxt": "Tebrikler, {userName}, <span class='Tcolor'>{winMoney}</span> USDT kazandı",
        "TS": "Paraşütle Atlama",
        "CQK1M": "1dk Bloklar",
        "CQK5M": "5dk Bloklar",
        "CQK": "10dk Bloklar (Zincirleme)",
        "HL": "1dk Kırmızı_Yeşil",
        "HL5M": "5dk Kırmızı_Yeşil",
        "SD1M": "1dk Diskler",
        "SD5M": "5dk Diskler",
        "Gogame": "Oyuna git",
        "yinli": "Kazanç",
        "munber": "Kazanç = Ticaret Karı + Etkinlik Bonusu + İndirim",
        "Gyinkui": "Oyun karı ve zararı",
        "Hyinkui": "Sözleşme karı ve zararı",
        "Hlijin": "Etkinlik Bonusu",
        "Yfanyong": "İndirim"
    },
    "version12": {
        "hl10mRule": "Her 10 dakikada bir, ilk ETH bloğunun hash değerinin son bitinden önüne doğru sayarak ilk 0-9'u tahmin edin, 1, 3, 5, 7, 9 Yeşil, 0, 2, 4, 6, 8 Kırmızı , 0 ve 5 de Mor.",
        "sd10mRule": "Her 10 dakikada bir ilk ETH bloğunun karma değerinin son dört bitini tahmin edin, her kod bir diske karşılık gelir, 0-7 beyaz, 8-f kırmızıdır. Aynı renkteki disklerin sayısı çift, aynı renkteki disklerin tek sayısı ise Tek'tir.",
        "SD10M": "10dk Diskler (Zincirleme)",
        "HL10M": "10dk Kırmızı_Yeşil (Zincirleme)",
        "refresh": "Yenile"
    },
    "version13": {
        "kaijiangzhong": "Resim çizme",
        "canyu": "Benim",
        "kaijiang": "Piyango çekildi",
        "join": "Katıl",
        "buyAmount": "Lütfen satın alma miktarını girin",
        "minBetAmount": "Minimum satın alma kopya sayısı: ",
        "maxBetAmount": "Maksimum satın alma kopya sayısı: ",
        "tipMax": "Lütfen maksimum kopya sayısından fazla satın almayın",
        "totalVal": "Toplam değer",
        "jindu": "İlerlemek",
        "leave": "Kalan",
        "goldNumber": "Kazanan Numara",
        "myGoldNumber": "Kodum",
        "val": "Değer",
        "mode": "Kalan",
        "unJoin": "Katılmadı",
        "calNumber": "Hesaplanıyor",
        "eachPrice": "Kopya başına fiyat",
        "currentIssue": "Bu turdaki rekorlar",
        "hisIssue": "Çekiliş öncesi 50 rekor",
        "showDetail": "Denetleme",
        "buy": "Satın alma",
        "buyTime": "Satın Alma Süresi",
        "luckNumber": "Ödül Numarası",
        "totalBuyAmount": "Toplam Alış Tutarı",
        "buyTip2": "Lütfen kopya başına doğru biçimi girin.",
        "goldMember": "Ödül Üyesi",
        "openTime": "Beraberlik zamanı",
        "Wtime": "Tahmini bakım süresi",
        "invitationNote": "Davet Talimatları",
        "p1": "<ins class='paramTitle'>Arkadaşlarını Davet Et</ins>Davet merkezine girin, davet bağlantısını veya davet kodunu kopyalayın ve arkadaşlarınızla paylaşın. Arkadaşlarınız davet kodunuzla kayıt olarak astınız olabilir.",
        "p2": "<ins class='paramTitle'>İndirim Kazanın</ins>Davetliler ticaret yaparken, üç davetli düzeyine kadar karşılık gelen indirimleri alabilirsiniz. Örneğin, arkadaşınız A'yı davet ederseniz, A, B'yi ve B, C'yi davet ederse, o zaman A, B ve C, platformda oyun, takas ve diğer işlemleri takas edebilir. Bu işlemlerde de karşılık gelen indirimler alabilirsiniz.",
        "fuk": "Lütfen ödeme",
        "guoq": "Süresi doldu",
        "exchtishi": "Yukarıdaki hesaba para göndermek için lütfen sertifikalı banka kartınızı kullanın.",
        "exchtishi2": "Lütfen havalelerin veya diğer sorunların ele geçirilmesini önlemek için havale sürecinde BTC, USDT ve diğer bilgileri not etmeyin.",
        "quxiaoOrder": "Siparişi iptal et",
        "chengong": "Başarıyla ödedim",
        "fukuanren": "Ödeme adı",
        "fukuanrenTi": "Lütfen ödeme adını girin",
        "zhanghao": "Ödeme Hesabı",
        "zhanghaoTi": "Lütfen ödeme hesap numarasını giriniz",
        "yihang": "Ödeme bankası",
        "yihangTi": "Lütfen ödeme bankasını doldurun",
        "jietu": "Ödeme ekran görüntüsü",
        "jietuTi": "Lütfen ödemenin ekran görüntüsünü yükleyin",
        "queren": "Lütfen ödeme yaptığınızı onaylayın.",
        "queren2": "Kötü amaçlı onay, hesabı doğrudan dondurur",
        "YYYG": "Şanslı 1U",
        "dragon": "Liderler Sıralaması",
        "paimng": "Sıralama"
    },
    "version15": {
        "only60Days": "Verileri yalnızca 60 gün içinde saklayın.",
        "only100Records": "Yalnızca son 100 kaydı göster.",
        "status": "Durum",
        "luckyWheel": "Şanslı Beraberlik",
        "option1": "Bonus",
        "option2": "Thanks",
        "option3": "Once More",
        "freeTimes": "Beraberlik sayısı",
        "p1": "Başarılı bir kayıttan sonra {registerGiveCount} çekiliş fırsatı elde edeceksiniz.",
        "p2": "Depozito tutarı dün {todayRechargeCondition} USDT'ye ulaşırsa (UTC+0 saat dilimine bağlıdır), {rechargeGiveCount} çekiliş fırsatı elde edebilirsiniz.",
        "p3": "Dün (UTC+0 zaman diliminde) geçerli bahislerin miktarı (spot ve kontrat hariç) {todayBettingCondition} USDT'ye ulaştı, o zaman {bettingGiveCount} çekiliş fırsatı elde edebilirsiniz.",
        "continuDraw": "Çizime Devam Et",
        "noFreeTimeTip": "Tüm çizim fırsatlarınız tükendi."
    },
    "version16": {
        "totalPerMoney": "Toplam siparişler",
        "notFu": "Bahis miktarı 0 olamaz",
        "find": "Keşfetmek",
        "channel": "Kanal"
    },
    "version17": {
        "drawWarmTip": "Şanslı çekiliş zamanlarının onaylanması on dakika ile yarım saat arasında sürebilir. Lütfen sabırla bekleyin.",
        "level": "Seviye",
        "upLimit": "Yükseltme Sınırlaması",
        "chatRoom": "Sohbet",
        "group": "Grup Sohbetleri",
        "privateChat": "Özel Sohbetler",
        "groupManager": "Grup Müdürü",
        "groupMember": "Grup üyesi",
        "groupSetting": "Grup Ayarları",
        "totalChargeMoney": "Birikimli Mevduat Tutarı",
        "vip9Tip": "Maksimum VIP seviyesine ulaşıldı",
        "dataTime": "Veri Süresi",
        "sortType": "Sıralama düzeni",
        "loginTime": "Giriş Zamanı",
        "lowerPeople": "Davetlilerin sayısı",
        "chargeNum": "Depozito Tutarı",
        "withdrawNum": "Para Çekme Tutarı",
        "lowerReport": "Takım Raporu",
        "lowerUserBetNum": "Davetlilerin Bahisleri",
        "lowerUserAwardNum": "Davetlilerin Kazanması",
        "lowerUserChargeNum": "Davetlilerin Depozitosu",
        "lowerUserWithdrawNum": "Davetlilerin Para Çekmesi",
        "firstRechargeNum": "İlk Para Yatırma Tutarı",
        "warmTip": "Bugünün verilerini otomatik olarak göster",
        "publishNotice": "Yayınla",
        "editNotice": "Bildirimi Düzenle",
        "noticeTime": "Bildirim Süresi",
        "inputNoticeTip": "Lütfen bildirimin içeriğini girin.",
        "tip": "'Hepsi Yasak' etkinleştirildikten sonra, yalnızca grup yöneticilerinin konuşmasına izin verilir.",
        "allNotSpeak": "Hepsi Yasak",
        "canSpeakLevel": "Konuşma Seviyesi",
        "eryuanqiquan": "İkili Opsiyon",
        "optionHelpText": "Bir sonraki zaman periyodunun iniş ve çıkışını tahmin edin. Her dönemin başındaki fiyat başlangıç fiyatı, her dönemin sonundaki fiyat ise uzlaşma fiyatıdır. Uzlaşma fiyatı başlangıç fiyatından büyükse artar (Artışı), uzlaşma fiyatı başlangıç fiyatından düşükse düşer (Düşüşü).",
        "optionRecord": "Opsiyon Kayıtları",
        "calcuteDirection": "Tahmin Yönü",
        "startPrice": "Başlangıç ​​fiyatı",
        "endPrice": "Uzlaşma Fiyatı",
        "equal": "Eşit",
        "waitResult": "Bekleyen yerleş",
        "up": "Artışı",
        "down": "Düşüşü",
        "findPassword": "Parola Al",
        "calTime": "Tahmin Süresi",
        "calWeek": "Tahmin Dönemi",
        "kanzhang": "Artışı",
        "kandie": "Düşüşü"
    },
    "version18": {
        "issue": "Döngü numarası",
        "vipSort": "VIP Seviye",
        "jinyan": "Sessiz",
        "weijinyan": "Sesi açıldı",
        "confirmDelete": "Üyeyi silmek istediğinizden emin misiniz?",
        "confirmUnSpeak": "Üyeyi sessize almak istediğinizden emin misiniz?",
        "confirmSpeak": "Üyenin sesini açmak istediğinizden emin misiniz?",
        "confirmAdd": "Üyeyi eklemek istediğinizden emin misiniz?",
        "addMember": "Yeni Üye Ekle",
        "delMember": "Üyeyi sil",
        "lockSpeak": "Sessiz",
        "unLockSpeak": "Sesi açıldı",
        "lockSpeakSelMember": "Üyenin sesini kapattı",
        "unLockSpeakSelMember": "Üyenin Sesi Açıldı",
        "noSelMemberTip": "Lütfen üyeyi seçin",
        "recentlyPlayGame": "Sık Oynanan",
        "sendMsg": "Mesaj gönder",
        "addGroupMember": "Yeni Üye Ekle",
        "picTip": "[Resim]",
        "time3": "1 hafta önce",
        "chooseAll": "Ters Çevir'i seçin",
        "personalPage": "Kişisel Ana Sayfa",
        "CQK3M": "3dk Bloklar",
        "HL3M": "3dk Kırmızı_Yeşil",
        "SD3M": "3dk Diskler"
    },
    "version19": {
        "avatar": "Avatar",
        "share": "Paylaş",
        "todayProfit": "Bugünün kazanç",
        "followBet": "Takip et",
        "Jump": "Git",
        "over": "Bitmiş",
        "optionNumber": "Opsiyon sayısı",
        "optionBet": "Opsiyon bahisleri",
        "optionReward": "Opsiyon kazanma",
        "optionProfit": "Opsiyon kar ve zararı",
        "RG_2_0": "Kırmızı",
        "RG_5_1": "Mor",
        "RG_2_1": "Yeşil",
        "DS_16_0": "1111",
        "DS_16_1": "0000",
        "DS_4_0": "1110",
        "DS_4_1": "1000",
        "DS_2_0": "Çift",
        "DS_2_1": "Tek",
        "BLK_2_0": "Büyük",
        "BLK_2_1": "Küçük",
        "BLK_2_2": "Tek",
        "BLK_2_3": "Çift",
        "BLK_4_0": "Büyük-Tek",
        "BLK_4_1": "Büyük-Çift",
        "BLK_4_2": "Küçük-Tek",
        "BLK_4_3": "Küçük-Çift"
    },
    "version20": {
        "server": "Müşteri servisi",
        "teacher": "Süpervizör",
        "financer": "Finans",
        "planer": "Planlayıcı",
        "chatPop": "Sohbet kayan pencere",
        "cancelText": "Kayan pencereyi iptal et",
        "betNumber": "Bahis seçenekleri",
        "resendMsg": "Mesaj gönderilemedi. Mesajı yeniden göndermek istiyor musunuz?",
        "delThisMsg": "Mesajı sil",
        "delThisMemberAllMsg": "Üyeden gelen tüm mesajları sil",
        "forbitThisMember": "Üyenin sesini kapattı",
        "delThisMember": "Üyeyi sil",
        "delTipModal": "Lütfen çalıştırılacak işlevi seçin."
    },
    "version21": {
        "USDT_account": "USDT Hesabı",
        "closeSwapTip": "Lütfen kapanış oranını seçin.",
        "hasRecharge": "Para yatırdı",
        "numberCoin": "Dijital Para Birimi",
        "none": "-",
        "fastCharge": "Hızlı para yatırma",
        "bankTransfer": "Banka transferi",
        "setLegalTip": "Lütfen itibari para biriminizi seçin",
        "legalCoin": "Fiat Para Birimi",
        "orderCreateTime": "Oluşturma Zamanı",
        "tradeOrderNumber": "İşlem Numarası",
        "completeTime": "Tamamlanma Süresi",
        "fastWithdraw": "Hızlı Para Çekme",
        "bindCardTip1": "Gönderildikten sonra değiştirilemeyeceği için lütfen geçerli kimlik kartınızdaki tam adı doldurun",
        "bindCardTip2": "Lütfen banka kartı bilgilerinizi giriniz"
    },
    "saas": {
        "fastChargeType": "Hızlı para yatırma",
        "numberCoinChargeType": "Dijital para yatırma",
        "humanChargeType": "Manuel para yatırma",
        "fastChannelWithdrawType": "Hızlı Kanal Çekme",
        "numberCoinWithdrawType": "Dijital Para Çekme",
        "humanWithdrawType": "Manuel Para Çekme",
        "fastChannelBackType": "Hızlı Kanal Dönüşü",
        "numberChannelBackType": "Dijital Para İadesi",
        "betType": "Bahis",
        "betProfitType": "Kâr",
        "betEqualType": "Berabere",
        "gameBackRewardType": "Oyun İndirimi",
        "swapBackRewardType": "Takas indirimi",
        "exchangeBackRewardType": "Spot İndirimi",
        "tranInType": "İçeriye transfer et",
        "tranOutType": "Dışarı Aktarma",
        "chargeRewardType": "Para Yatırma Bonusu",
        "firstChargeRewardType": "İlk Para Yatırma Bonusu",
        "regRewardType": "Kayıt Bonusu",
        "upGradeRewardType": "Promosyon Bonusu",
        "luckyRewardType": "Şanslı Beraberlik",
        "realNameRewardType": "kimlik doğrulama Bonusu",
        "betRewardType": "Bahis Bonusu",
        "dailyFirstChargeRewardType": "Günlük İlk Para Yatırma Bonusu",
        "upScoreType": "Manuel Artış",
        "downScoreType": "Manuel Azaltma",
        "unRecharge": "Depozito yok",
        "canWithdrawBalance": "Çekilebilir Bakiye",
        "withPopTip1": "Bitmemiş akış varsa para çekme işlemine izin verilmez.",
        "withPopTip2": "Tamamlanmamış akışın kısmının, geri çekilirken akış ücretinin %{flowFee} kadarını ödemesi gerekir.",
        "alterBankCardNameTip": "Adınızı değiştirmeniz gerekiyorsa lütfen müşteri hizmetleri ile iletişime geçin",
        "arriveMoney": "Alınan Tutar",
        "withdrawNum": "Para Çekme Tutarı",
        "failReason": "Arıza Nedeni",
        "tradeHash": "TXID",
        "waitCoin": "Kredi Bekleme",
        "zicang": "Varlıklar",
        "otherMoney": "Diğer Varlıkların Tahmini",
        "moneyList": "Varlık Listesi",
        "onlyShowSwap": "Sadece Takas",
        "hideZeroMoney": "Küçük Bakiyeleri Gizle",
        "chargeFlowTip1": "USDT hesabına yüklenen bakiye, ödenmemiş ciroyu %{deposit} oranında artıracaktır. Örneğin, depozito 1000USDT ise, tamamlanmamış ciroya {depositValue}USDT eklenecektir.",
        "chargeFlowTip2": "Bitmemiş akış, oyunun / ikili seçeneğin etkin bahsi yoluyla düşülebilir. Örneğin, tamamlanmamış akış 200USDT ise ve oyunda geçerli bir 10USDT bahis oluşturulmuşsa, tamamlanmamış akışta 190USDT kalmıştır.",
        "chargeRecord": "Mevduat Kayıtları",
        "withdrawRecord": "Para Çekme Kayıtları"
    },
    "version22": {
        "verificationCode": "Captcha",
        "userNameErr": "Kullanıcı adının biçimi yanlış. Lütfen içerebilecek . ve @ 6-32 harf veya alfasayısal kombinasyonlar girin.",
        "tip": "İpuçları",
        "because": "Sebep",
        "no": "Numara",
        "auditError": "Denetim Başarısız",
        "auditSuccess": "Denetim Başarılı",
        "fundPassword": "Fon şifresinin formatı yanlış. Lütfen 6 hane girin.",
        "allEntrust": "Sipariş Geçmişi",
        "dealAmount": "İşlem tutarı",
        "watcherTeamForm": "Ekip Raporlarını Görüntüle",
        "overview": "Genel bakış",
        "stopBet": "Bu süre içinde sipariş kapatılmıştır. Lütfen bahis yapmayı bırakın.",
        "balance": "Kalan bakiye",
        "exchangeOrderMinVolume": "Minimum sipariş miktarı: ",
        "exchangeOrderMaxVolume": "Maksimum sipariş miktarı: ",
        "BLK5MC": "5dk Bloklar (Zincirleme)",
        "RG5MC": "5dk Kırmızı_Yeşil (Zincirleme)",
        "DS5MC": "5dk Diskler (Zincir Yukarı)",
        "forbidTrade": "İşlem modülü, işlemleri yasakladı",
        "minTurnoverNum": "Minimum ciro: ",
        "minSwapOpenNum": "Minimum sözleşme sayısı x",
        "maxSwapOpenNum": "Şu anda maksimum sözleşme sayısı x'tir",
        "activity": "Aktivite",
        "profitRecord": "zaferler",
        "service": "Müşteri servisi",
        "help": "Yardım",
        "totalAssets": "Toplam değer",
        "readAllLetterTip": "Tüm mesajları okundu olarak ayarlamak istediğinizden emin misiniz?",
        "myTeam": "Benim takımım",
        "lv1": "1. Sınıf",
        "lv2": "2. Sınıf",
        "lv3": "3. Sınıf",
        "lvAll": "Sınıf 1/2/3",
        "calDataTime": "İstatistiksel Zaman",
        "dataUpdateTip": "Veri güncelleme zamanı: {date}",
        "total": "Toplam Kopyalar",
        "lastTopUserName": "Üstün Kullanıcı Adı",
        "humanAdd": "Manuel Artış",
        "humanSub": "Manuel Azaltma",
        "realNameTime": "Kimlik Doğrulama Süresi",
        "chargeTime": "Para Yatırma Süresi",
        "reSubmit": "Tekrar gönder",
        "goldMoney": "Kazanan Tutar",
        "username": "Kullanıcı",
        "myNum": "Kodum",
        "waitOpen": "Çekilişi beklir",
        "buyAmount": "Satın alınacak kopya sayısı",
        "bugNum": "İlgili kopyaları satın alın",
        "gainCode": "Piyango kodunu alın",
        "totalNumOpenReward": "Piyango, toplam kopya sayısı tükendikten sonra açılacaktır",
        "winnerGainAllReward": "Kazanan tüm ödülleri alacak",
        "teamLevel": "Takım Seviyesi",
        "SD_serialGame": "Diskler",
        "RG_serialGame": "Kırmızı_Yeşil",
        "BLK_serialGame": "Bloklar",
        "codeTip": "Mevduat Adresi QR Kodu",
        "relateTopUser": "Üst",
        "lv1Num": "1. Sınıf - Davetliler",
        "lv2Num": "2. Sınıf - Davetliler",
        "lv3Num": "3. Sınıf - Davetliler",
        "singleLevelTxt": ". Sınıf",
        "wrondPasswordCorrect": "Oturum açma parolasının biçimi yanlış. Lütfen 8-32 harf ve rakamdan oluşan bir kombinasyon girin",
        "withdrawAccount": "Para Çekme Hesabı",
        "optionModeShutTip": "{minute} dakika modu şu anda bakımda",
        "optionModuleShutTip": "Bakımda",
        "gameClosed": "Oyun kapandı",
        "doBet": "Bahis",
        "reAlterBindCard": "Fiat para biriminiz banka kartınızın para birimiyle tutarsız. Lütfen banka kartı bilgilerini zamanında güncelleyin",
        "t2": "Çekilişten önceki oyunun son 50 satın alma kaydının zaman değerlerinin (UTC+0) toplamı itibariyle",
        "emailErr": "Lütfen doğru e-posta adresini girin.",
        "bigSymTip": "<Büyük>'ü  temsil eder",
        "smallSymTip": "<Küçük>'ü temsil eder",
        "EvenSymTip": "<Çift ​>'i temsil eder",
        "OddSymTip": "<Tek>'i  temsil  eder",
        "exchangeNum": "Spot Kullanıcılar",
        "yesterdayAward": "Dün Kazanan",
        "yijixiaji": "1. Sınıf - Davetli",
        "erjixiaji": "2. Sınıf - Davetli",
        "sanjixiaji": "3. Sınıf - Davetli",
        "p3": "<ins class='paramTitle'>Takım Düzeyi</ins>Sınıf 1 - davetliler ne kadar çok terfi ettirilirse, ekip düzeyi o kadar yüksek olur ve yararlanabileceğiniz indirim o kadar yüksek olur. Takım seviyesi LV1-LV6'ya bölünmüştür. Yükseltme kuralları için aşağıdaki tabloya bakın; burada N, ücretlendirilen 1. sınıf davetlilerin sayısıdır.",
        "p4": "<ins class='paramTitle'>Oyun, Saniye Sözleşmesi, Seçeneğin İndirim Oranı</ins>Davetliler oyunlara ve ikili seçeneklere bahis yaptıklarında, bahis tutarlarına karşılık gelen indirim alabilirsiniz.",
        "p5": "<ins class='paramTitle'>Spot ve Swap İndirim Oranı</ins>Davetliler spot ve takas işlemleri yaptığında, işlem komisyonlarına karşılık gelen indirim alabilirsiniz. Komisyon USDT dışındaki para birimlerinde ise, indirim gerçek zamanlı döviz kuruna göre USDT'ye dönüştürülür ve USDT hesabınıza aktarılır.",
        "flag": "Özellikler",
        "noZero": "Transfer miktarı 0 olamaz.",
        "networkTip": "Ağ hatası, lütfen sayfayı yenileyin ve yeniden yükleyin.",
        "inviteChargeTip": "Takım seviyesinin yükseltilmesi, 1. sınıf - başarıyla şarj olan davetlilerin sayısına bağlıdır.",
        "canWithdrawNum": "Çekilebilir Bakiye",
        "withTip": "Lütfen para çekme tutarını girin.",
        "withModalTip": "Hizmet bedeline akış ücreti ve madencilik ücreti dahildir.",
        "totalUpNum": "Toplam Kişi",
        "chargeRangeTip": "Depozito tutarı şöyle olmalıdır: ",
        "jiaocheng": "Talimat",
        "spare1": "Alternatif Bilgi 1",
        "spare2": "Alternatif Bilgi 2",
        "loadAllGroupMsg": "7 gün içindeki tüm mesajlar yüklendi",
        "realMember": "Tam üyelik",
        "testMember": "Test üyesi",
        "noBlankMsg": "Boş mesaj gönderemezsiniz",
        "maxLength1500": "İçerik çok uzun olduğu için mesaj gönderilemedi",
        "sendToMore": "İstekler çok sık olduğu için mesaj gönderilemedi",
        "countdown": "Geri sayım",
        "toLongRemark": "Çok fazla kelime, lütfen bazı kelimeleri silin",
        "tip1": "<p>* Veriler istatistiksel zamana tabidir. Veriler her saat güncellenir. En son güncelleme zamanı: {time}; Bunlar arasında, kayıt kullanıcıları / doğrulama kullanıcıları / ilk para yatırma kullanıcıları / İlk para yatırma tutarı gerçek zamanlı olarak güncellenir</p>",
        "tip2": "<p>* USDT bakiyesinin istatistiksel zamanla hiçbir ilgisi yoktur ve her zaman takımın mevcut toplam bakiyesini gösterir</p>",
        "tip3": "* Para yatırma / çekme / bahis / kazanma / iade miktarı istatistiksel süreye tabidir. Veriler her saat güncellenir. En son güncelleme zamanı: {time}",
        "tip4": "* Diğer veriler istatistiksel zamandan bağımsızdır ve en son veriler her zaman görüntülenir",
        "wrongImgType": "Desteklenmeyen dosya formatı",
        "addNewNotice": "Ekle",
        "noChoose": "Seçili değil",
        "nullCoin": "/",
        "thankyou": "Katılım için teşekkürler",
        "oncemore": "Tekrar",
        "tip5": "Döviz kuru dalgalanabileceğinden, lütfen gerçek tutara bakın",
        "xiugaichenggong": "Değişiklik başarılı, lütfen tekrar giriş yapın",
        "entrustNoZero": "Yetki verilen miktar 0 olamaz",
        "herTeam": "Onun takımı",
        "betMoney": "Lütfen bahis miktarını giriniz",
        addFilterMember : 'Filtrelenen tüm üyeleri ekle',
        delFilterMember : 'Filtrelemeden sonra tüm üyeleri kaldır',
        forbidFilterMember : 'Filtrelemeden sonra tüm üyeleri sessize alır',
        freeFilterMember : 'Filtrelenen tüm üyelerin sesini aç',
        theme1 : 'Oyun Teması 1',
        theme2 : 'Oyun Teması 2',
        theme3 : 'Ex Teması 1',
        theme4 : 'Ex Teması 2',
        themeTitle : 'Tema',
        maxTradeMoney: 'Maksimum ciro '
    },
    "version23": {
        inviteCenter: "Davet Merkezi",
        search: 'Arama',
        active: 'Eylem',
        openDirect: 'Sonuç Yönü',
        profitAndLossAmount: 'P/L',
        levelMax: "En yüksek seviyeye ulaşıldı",
        next: 'Sonraki Sayfa',
        prev: 'Önceki Sayfa',
        secondRecord : 'Saniye Sözleşme Kayıtları',
        entrusting : 'Konsinye',
        secondReward : 'Saniye Sözleşmesi Kazanma',
        secondBet : 'Saniye Kontratlı Bahis',
        secondProfit : 'İkinci sözleşme kar ve zararı',
        RD100N : 'Rastgele 100',
        secondNum : 'İkinci sözleşme numarası',
        rebackTip : 'İndirimler her saat başı yapılır, lütfen sabırlı olun',
        secondOption : 'Saniye Sözleşmesi',
        qiquan : 'Seçenekle',
    exportBtn : 'İhracat',
    "SB1M": "1dk UçanTekne",
    "SB3M": "3dk UçanTekne",
    "SB5M": "5dk Uçan Tekne",
    "SBTM": "10dk Uçan Tekne",
  },
  version24 : {
    goldBoom : 'Mayına basmak',
    'MS' : 'Mayın tarama',
    spRecord : 'Spor Kayıtları-Ponymuah',
    "QT1M": "1dk SICBO",
    "QT3M": "3dk SICBO",
    "QT5M": "5 dk SICBO",
    "QTTM": "10dk SICBO",
    "KN1M": "1dk KENO",
    "KN3M": "3dk KENO",
    "KN5M": "5dk KENO",
    "KNTM": "10dk KENO",
    "electronRecord": "Arcade Kayıtları-Evolution",
    "LOT1M": "1dk LOTTO",
    "LOT3M": "3dk LOTTO",
    "LOT5M": "5dk LOTTO",
    "LOTTM": "10dk LOTTO",
    'LOTW3' : 'LOTTO',

    "RLT1M": "1dk Rulet",
    "RLT3M": "3dk Rulet",
    "RLT5M": "5dk Rulet",
    "RLTTM": "10dk Rulet",
    "LK281M": "1dk Şanslı 28",
    "LK283M": "3dk Şanslı 28",
    "LK285M": "5dk Şanslı 28",
    "LK28TM": "10dk Şanslı 28",
    "liveRecord": "Canlı Kayıtları-Ezugi",
    evoLiveRecord : 'Canlı Kayıtları-Evolution',
    pbRecord : 'Spor Kayıtları-PinnacleBet',
      LIMBO : 'LIMBO',
      HILO : 'HILO',
    'FSLOT' : 'Meyve',
    'RULTI' : 'Tekerlek',
    'RULTI_2-075_0' : 'Siyah',
    'RULTI_3-176_0' : 'Kırmızı',
    'RULTI_5-4_0' : 'Mavi',
    'RULTI_54_0' : 'Yeşil',
	'RB' : 'Kırmızı_Siyah',
	'RB_2-142_0' : 'Kırmızı',
	'RB_15_0' : 'Beyaz',
	'RB_2-142_1' : 'Siyah',
	
    'FST' : '4 Yıldızlı Piyango',
    'FST1M' : '1dk 4 Yıldızlı Piyango',
    'FST5M' : '5dk 4 Yıldızlı Piyango',
    'FSTTM' : '10dk 4 Yıldızlı Piyango',
    'FST1D' : '1 gün 4 Yıldızlı Piyango',

    'TS' : '3 Yıldızlı Piyango',
    'TS1M' : '1dk 3 Yıldızlı Piyango',
    'TS5M' : '5dk 3 Yıldızlı Piyango',
    'TSTM' : '10dk 3 Yıldızlı Piyango',
    'TS1D' : '1 gün 3 Yıldızlı Piyango',

    'TWLT1M' : '1dk Büyük Loto',
    'TWLT5M' : '5dk Büyük Loto',
    'TWLTTM' : '10dk Büyük Loto',
    'TWLTW2' : 'Büyük Loto',
    'TWLT' : 'Büyük Loto',


    'SPLT1M' : '1dk Süper Loto',
    'SPLT5M' : '5dk Süper Loto',
    'SPLTTM' : '10dk Süper Loto',
    'SPLTW2' : 'Süper Loto',

    'SPLT' : 'Süper Loto',
  },
  version25 : {
    sport : 'Spor Dalları-Ponymuah',
    live :'Canlı-Ezugi',
    electron : 'Arcade-Evolution',

    sport_index : 'Spor Dalları',
    live_index :'Canlı',
    electron_index : 'Arcade',

    PG : 'Arcade-PocketGame',
    PP : 'Arcade-PragmaticPlay',
    "sportsNum": "Spor Kullanıcıları",
    "sportsAward": "Spor Kazanan",
    "sportsBet": "Spor Bahisleri",
    "sportsProfit": "Spor karı",

    electronNum : 'Arcade Kullanıcıları',
    electronAward : 'Arcade Kazanan',
    electronBet:'Arcade Bahisleri',
    electronProfit : 'Arcade Karı',

    liveNum : 'Canlı Kullanıcıları',
    liveAward : 'Canlı Kazanan',
    liveBet:'Canlı Bahisleri',
    liveProfit : 'Canlı Karı',
		
	Num : 'Kullanıcıları',
    Award : 'Kazanan',
    Bet:'Bahisleri',
    Profit : 'Karı',

    ourGame : 'Orijinal Oyunlar',
    "t1": "Tonlarca Oyun, Tek Noktadan Oyun",
    "t2": "Dünyanın lider oyun platformu",
  },
  version26 : {
    liveTeamReport : 'Gerçek zamanlı takım raporları',
    tip2 : `Son 24 saat içinde sadece zamanı seçebilirsiniz`
  },
  version27 : {
    PGrecord : 'Arcade Kayıtları-PocketGame',
    PPRecord : 'Arcade Kayıtları-PragmaticPlay',

  }
}